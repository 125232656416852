import { Box, Grid, Typography } from "@mui/material";
import { FullPageTemplate } from "ad/templates/FullPageTemplate";
import { useRequest } from "ahooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getWiseTransferDetail,
  GetWiseTransferDetailResponse,
} from "services/APIService/Transfers/TransfersApi";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";
import ConversionRate from "./components/ConversionRate";
import DownloadReceiptButton from "./components/DownloadReceiptButton";
import RecipientInfo from "./components/RecipientInfo";
import TransferTimeline from "./components/TransferTimeline";
import LoadingPage from "../../ad/molecules/LoadingPage";

const STATUS_HAS_RECIEPT = ["outgoing_payment_sent"];
export default function PaymentDetails() {
  let [searchParams] = useSearchParams();
  const transfer_id =
    useParams()["transfer_id"] || searchParams.get("transfer_id");
  const { t } = useTranslation();
  useEffect(() => WebviewService.onOpenPage(WV_MESSAGE.PAYMENT_DETAILS));

  const { data: transferDetail, loading } = useRequest<
    GetWiseTransferDetailResponse | null,
    []
  >(
    () => {
      if (transfer_id) return getWiseTransferDetail(transfer_id, true, true);
      return Promise.resolve(null);
    },
    { refreshDeps: [transfer_id] }
  );

  if (loading || !transferDetail) return <LoadingPage />;

  const { transfer, quote, recipient } = transferDetail;
  const { transfer_id: wiseTransferId } = transfer;
  const {
    fee,
    rate,
    source_amount: sourceValue,
    source_currency: sourceCurrency,
    target_amount: targetValue,
    target_currency: targetCurrency,
  } = quote!;
  const { account_summary, account_holder_name: full_name } = recipient!;

  const isDownloadReceiptAvaiable =
    transfer && STATUS_HAS_RECIEPT.includes(transfer.wise_status);
  return (
    <FullPageTemplate
      header={
        <Box
          sx={{
            py: 6,
            px: 5,
          }}
        >
          <ConversionRate
            sendAmount={{ amount: sourceValue, currency: sourceCurrency }}
            receiveAmount={{ amount: targetValue, currency: targetCurrency }}
          />
        </Box>
      }
      footer={
        <Grid
          sx={{
            background: "#f8f8f8",
          }}
        >
          {isDownloadReceiptAvaiable && (
            <DownloadReceiptButton transferId={transfer_id!} />
          )}
        </Grid>
      }
      sx={{ borderRadius: "16px 16px 0px 0px", background: "#f8f8f8" }}
    >
      <Box
        sx={{
          py: 5,
          px: 3,
        }}
      >
        <RecipientInfo name={full_name} recipientId={account_summary} />
        <Grid item sx={{ ml: -1 }}>
          <TransferTimeline transfer_id={transfer_id!} />
          <Box sx={{ px: 6 }}>
            <Typography variant="body1" sx={{ color: "#3B464B" }}>
              {t("transaction.fee_include_exchange_rate", {
                fee,
                exchangeRate: rate,
              })}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              {t("transaction.wise_transfer_id", { wiseTransferId })}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </FullPageTemplate>
  );
}

import { SvgIcon } from "@mui/material";
import React from "react";

type IconProps = React.ComponentProps<typeof SvgIcon>;
export default function LoaderIcon(props: IconProps) {
  const defaultProps: IconProps = {
    width: 25,
    height: 25,
    viewBox: "0 0 25 25",
    fill: "none",
  };

  const newProps: IconProps = { ...defaultProps, ...props };
  return (
    <SvgIcon {...newProps}>
      <path
        d="M12.5938 2.10938V6.10938"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5938 18.1094V22.1094"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52344 5.03906L8.35344 7.86906"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8359 16.3477L19.6659 19.1777"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.59375 12.1094H6.59375"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5938 12.1094H22.5938"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52344 19.1777L8.35344 16.3477"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8359 7.86906L19.6659 5.03906"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

import { useRequest } from "ahooks";
import React, { PropsWithChildren } from "react";

import { useSearchParams } from "react-router-dom";
import { getWiseTransferDetail } from "services/APIService/Transfers/TransfersApi";
import { StoreService, STORE_KEYS } from "services/StoreService";

export const TransferContainer: React.FC<
  PropsWithChildren<{ loadingComponent?: React.ReactElement }>
> = ({ children, loadingComponent }) => {
  let [searchParams] = useSearchParams();

  const { loading } = useRequest(async () => {
    const transfer_id = searchParams.get("transfer_id");
    if (transfer_id) {
      const data = await getWiseTransferDetail(transfer_id);
      StoreService.setData(STORE_KEYS.QUOTE_ID, data.transfer.quote_uuid);
      StoreService.setData(
        STORE_KEYS.RECIPIENT_ID,
        `${data.transfer.recipient_id}`
      );
      return data;
    }
    return undefined;
  });

  return <>{loading ? loadingComponent : children}</>;
};

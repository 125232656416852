import { CircularProgress } from "@mui/material";
import { VerticalCenteredBox } from "ad/atoms/VerticalCenteredBox";
import { PageDialog } from "ad/molecules/PageDialog";
import { PostLoginCreated } from "./PostLoginCreated";
import { usePersonalPostLogin } from "./usePersonalPostLogin";

type TProps = { open?: boolean };

export const PersonalPostLogin = ({ open }: TProps) => {
  const { status, onNext } = usePersonalPostLogin();
  return status === "created" ? (
    <PostLoginCreated onNext={onNext} />
  ) : (
    <PageDialog open={!!open}>
      <VerticalCenteredBox sx={{ alignItems: "center" }}>
        <CircularProgress size="1rem" />
      </VerticalCenteredBox>
    </PageDialog>
  );
};

import { useRef, useState } from "react";

type TProps<T> = {
  before?: (value: T) => void;
  callback?: (value: T) => void;
  ms?: number;
};

export const useDebouncer = <TVALUE extends Object>({
  before,
  callback,
  ms = 500,
}: TProps<TVALUE> = {}) => {
  const [value, setValue] = useState<TVALUE>();
  const timer = useRef<NodeJS.Timeout>();

  const clear = () => {
    clearTimeout(timer.current);
    setValue(undefined);
  };

  const update = (value: TVALUE, immediate = false) => {
    clearTimeout(timer.current);
    before?.(value);
    timer.current = setTimeout(
      () => {
        setValue(value);
        callback?.(value);
      },
      immediate ? 0 : ms
    );
  };

  return {
    value,
    clear,
    update,
  };
};

import { APIService } from "services/APIService";
import { TErr, TEvalAmount, TGetLimits } from "services/APIService/types";
import { StoreService } from "services/StoreService";
import { TCurrency, TData } from "./types";

const CARD_CURRENCY = "USD";
const CARD_GET_CURRENCY = "CAD";

export const asNumber = (value: string | undefined) =>
  value ? parseFloat(value.replace(/[^0-9.]/g, "")) : null;

export const evalAmount = async (
  data: TData,
  minAmount: number
): Promise<TErr | TEvalAmount["OUT"] | undefined> => {
  const getMoney = asNumber(data.getMoney);
  const sendMoney = asNumber(data.sendMoney);
  if (
    data.sendCurrency &&
    data.getCurrency &&
    ((data.changed === "get" && getMoney) ||
      (data.changed === "send" && (sendMoney || 0) >= minAmount))
  ) {
    const bishop = StoreService.getBishop();
    const results = await APIService.evalAmount(
      {
        ...bishop.data,
        ...bishop.env,
        source_currency: data.sendCurrency,
        source_amount: data.changed === "send" ? sendMoney : null,
        target_currency: data.getCurrency,
        target_amount: data.changed === "send" ? null : getMoney,
      },
      bishop.env
    );
    return results;
  }
  return undefined;
};

export const getBalance = async (): Promise<
  { currency: string; balance: number; getCurrency: string } | undefined
> => {
  const bishop = StoreService.getBishop();
  const cards = await APIService.getCards(bishop.data, bishop.env);
  if ("data" in cards) {
    const ting = cards.data.auto_method?.ting;
    const activeCard =
      ting &&
      cards.data.cards.find(
        (card) => card.ting === ting && card.currency_code === CARD_CURRENCY
      );
    if (activeCard) {
      const balance = await APIService.getBalance(
        {
          ...bishop.data,
          card_ting: activeCard.ting,
        },
        bishop.env
      );
      if ("data" in balance) {
        return {
          currency: activeCard.currency_code,
          balance: balance.data.balance,
          getCurrency: CARD_GET_CURRENCY,
        };
      }
    }
  }
  return undefined;
};

export const getLimits = async (): Promise<TGetLimits["OUT"] | undefined> => {
  const bishop = StoreService.getBishop();
  const { user_id } = bishop.env;
  const out = await APIService.getLimits(
    { ...bishop.data, user_id, provider_id: 1 },
    bishop.env
  );
  return "error" in out ? undefined : out;
};

export const getCurrencies = async (): Promise<TCurrency[] | undefined> => {
  const bishop = StoreService.getBishop();
  const currencies = await APIService.getCurrencies(
    { ...bishop.data, ...bishop.env },
    bishop.env
  );
  if ("error" in currencies) {
    return undefined;
  }
  return currencies.data.unique_currencies
    .map(({ currency: code, currency_name: label, popular }) => ({
      code,
      label,
      popular,
      search: `${label} ${code}`.toLowerCase(),
    }))
    .sort(({ label }, { label: label2 }) => label.localeCompare(label2));
};

export const evalReached: (
  data: TData,
  send: number
) => Pick<TData, "limitReached" | "limitReachedAlert"> = (data, send) => {
  const reached = data.limitList?.find(({ remaining }) => remaining < send);
  return {
    limitReached: reached?.period,
    limitReachedAlert:
      !data.limitReached && !!reached ? true : data.limitReachedAlert,
  };
};

import { Resource } from "i18next";
import { account } from "./account";
import { home } from "./home";
import { money } from "./money";
import { personal } from "./personal";
import { transaction } from "./transaction";
import { root } from "./root";
import { manageRecipients } from "./mange_recipients";
import { failed } from "./failed";
export const resources: Resource = {
  en: {
    translation: {
      ...root.en,
      account: account.en,
      home: home.en,
      money: money.en,
      personal: personal.en,
      transaction: transaction.en,
      manage_recipients: manageRecipients.en,
      failed: failed.en,
    },
  },
};

import { StoreService } from "services/StoreService";
import { TBishopEnv } from "./types";

export const isValidStatus = (status: number, others?: number[]) =>
  (status >= 200 && status <= 299) || others?.includes(status);

export const commomHeaders = () => ({
  "Content-Type": "application/json",
});

export const bishopHeaders = (env: TBishopEnv) => ({
  "X-Bishop": env.bibson,
});

export function buildGeneralAxiosConfig() {
  const bishopData = StoreService.getBishop();
  return {
    params: {
      ...bishopData.data,
      user_id: bishopData.env.user_id,
    },
    headers: {
      "x-bishop": bishopData.env.bibson,
      "Content-Type": "application/json",
    },
  };
}

import { Icon, styled } from "@mui/material";
import { ConfigService } from "services/ConfigService";

type TProps = {
  country?: string;
  fontSize?: Parameters<typeof Icon>[0]["fontSize"];
  rounded?: boolean;
  sx?: Parameters<typeof Icon>[0]["sx"];
};

const FlagImage = styled("img")(({ rounded }: Pick<TProps, "rounded">) => ({
  ...(rounded ? { borderRadius: "50%" } : undefined),
  objectFit: rounded ? "cover" : "contain",
  width: "100%",
  height: "100%",
}));

export const FlagIcon = ({ country, fontSize, rounded, sx }: TProps) => {
  const url = country && ConfigService.evalFlagUri(country);
  return (
    <Icon fontSize={fontSize} sx={sx}>
      {url && <FlagImage src={url} alt="" rounded={rounded} />}
    </Icon>
  );
};

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LimitListItem } from "./LimitListItem";
import { TLimit } from "./types";

import { ReactComponent as BulbIcon } from "../../assets/images/bulb.svg";

export const LimitList = ({ list }: { list?: TLimit[] }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <Accordion
      sx={{ borderLeft: "solid 10px #B33B3F", borderRadius: "8px" }}
      expanded={open}
      onChange={() => setOpen((open) => !open)}
      variant="outlined"
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body2" sx={{ color: "#000000" }}>
          <IconButton>
            <BulbIcon />
          </IconButton>
          {t("money.limits")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingX: 6 }}>
        {list?.map((item) => (
          <LimitListItem key={item.label} item={item} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

type TProps = {
  open: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
};

export const AlertDialog = ({ open, header, footer, children }: TProps) => (
  <Dialog open={open}>
    {header && <DialogTitle>{header}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
    {footer && (
      <DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
        {footer}
      </DialogActions>
    )}
  </Dialog>
);

import { useEffect, useMemo, useState } from "react";
import { getCurrencies } from "./functions";
import { TCurrency } from "./types";

export const useMoneyCurrencyModal = () => {
  const [search, setSearch] = useState<string>("");
  const [currencies, setCurrencies] = useState<TCurrency[]>();

  useEffect(() => {
    const loadCurrencies = async () => {
      const list = await getCurrencies();
      if (list) setCurrencies(list);
    };
    loadCurrencies();
  }, []);

  const [popularCurrencies, otherCurrencies] = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filtered = currencies?.filter(
      ({ search }) => search.indexOf(searchLower) >= 0
    );
    return [
      filtered?.filter(({ popular }) => popular),
      filtered?.filter(({ popular }) => !popular),
    ];
  }, [currencies, search]);

  return {
    search,
    setSearch,
    popularCurrencies,
    otherCurrencies,
  };
};

import { useRequest } from "ahooks";
import axios from "axios";
import { useWiseUser } from "hooks/useWiseUser";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  createAnonymousQuote,
  CreateQuoteAnominousParam,
  CreateQuoteProfileParam,
  createQuoteWithProfile,
} from "services/APIService/Quotes/QuotesApi";
import { WiseQuote } from "services/APIService/Quotes/types";
import { TErr, TErrData } from "services/APIService/types";
import { FormatterService } from "services/FormatterService";
import { saveQuoteIdToStorage } from "services/StoreService";
import { TData } from "./types";

const ROUTE_LINK = "/account/link";
const ROUTE_RELINK = "/account/relink";

const CURRENCY_ERRORS_CODE = ["173001"];

export type TCreateQuote = WiseQuote | { profile_id: string } | TErr;

export const useCreateQuote = (
  props: TData
): {
  executeCreatingQuoteAsync: Function;
  loading: boolean;
  navigateNextPage: Function;
} => {
  const { isLoggedIn, profileId } = useWiseUser();

  const navigate = useNavigate();

  const {
    sendCurrency: source_currency,
    getCurrency: target_currency,
    sendMoney: source_amount,
    getMoney: target_amount,
    changed,
  } = props;

  const createQuote = useCallback(async (): Promise<
    TCreateQuote | undefined
  > => {
    const quoteParam: Partial<CreateQuoteAnominousParam> = {
      source_currency,
      target_currency,
    };
    if (changed === "send") {
      quoteParam.source_amount = FormatterService.toNumber(source_amount || 0);
    } else {
      quoteParam.target_amount = FormatterService.toNumber(target_amount || 0);
    }

    // TODO: move this to a central place
    localStorage.setItem("quoteParams", JSON.stringify(quoteParam));
    if (!isLoggedIn) {
      return await createAnonymousQuote(
        quoteParam as CreateQuoteAnominousParam
      );
    }
    try {
      if (Number(profileId || 0) > 0)
        return await createQuoteWithProfile({
          ...quoteParam,
          profile_id: profileId,
        } as CreateQuoteProfileParam);
    } catch (err) {
      const errData = axios.isAxiosError(err)
        ? (err.response?.data as TErrData)
        : undefined;
      if (CURRENCY_ERRORS_CODE.includes(errData?.errors?.[0].code || ""))
        return {
          error: errData?.errors?.[0]?.message || String(err),
          method: "createQuote",
        };
      return { profile_id: `${profileId}` };
    }
  }, [
    profileId,
    isLoggedIn,
    source_currency,
    target_currency,
    source_amount,
    target_amount,
    changed,
  ]);

  const { runAsync: executeCreatingQuoteAsync, loading } = useRequest<
    TCreateQuote | undefined,
    []
  >(createQuote, {
    manual: true,
  });

  const navigateNextPage = (quote: WiseQuote | undefined): void => {
    if (quote?.profile_id) {
      navigate(
        `/recipients/list?quote_id=${quote.quote_id}&profile_id=${quote.profile_id}`
      );
    } else {
      if (quote) saveQuoteIdToStorage(quote.quote_id);
      navigate(profileId === 0 ? ROUTE_RELINK : ROUTE_LINK);
    }
  };

  return {
    executeCreatingQuoteAsync,
    loading,
    navigateNextPage,
  };
};

import { Typography } from "@mui/material";
import { VerticalCenteredBox } from "ad/atoms/VerticalCenteredBox";
import { PageDialog } from "ad/molecules/PageDialog";
import LoaderIcon from "app/components/icons/LoaderIcon";
import { t } from "i18n";
import { useNavigate } from "react-router-dom";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";
import { usePersonalPreCreateModal } from "./usePersonalPreCreateModal";

type TProps = { open?: boolean };

export const PersonalPreCreateModal = ({ open }: TProps) => {
  const { data: redirectLink, loading, error } = usePersonalPreCreateModal();
  const navigate = useNavigate();

  if (error) {
    navigate("/failed/creating_profile_failed", { replace: true });
    return null;
  }

  if (!loading && redirectLink) {
    WebviewService.onOpenPage(WV_MESSAGE.PERSONAL);
    window.open(redirectLink, "_self");
    return null;
  }

  return (
    <PageDialog open={!!open}>
      <VerticalCenteredBox sx={{ alignItems: "center" }}>
        <LoaderIcon sx={{ animation: "spin 2s linear infinite" }} />
        <Typography variant="subtitle2" sx={{ fontWeight: 700, mt: 3 }}>
          {`${t("personal.pre_create")}`}
        </Typography>
      </VerticalCenteredBox>
    </PageDialog>
  );
};

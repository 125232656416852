import React from "react";
import { Box } from "@mui/material";

function abbreviateName(name: string): string {
  const [firstName, lastName] = name.split(" ");
  if (!lastName) {
    return firstName.slice(0, 2).toUpperCase();
  }
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
}

interface RecipientAvatarProps {
  name: string;
  avatarUrl?: string;
}

const RecipientAvatar: React.FC<RecipientAvatarProps> = ({
  name,
  avatarUrl,
}): JSX.Element => {
  const abbrName = abbreviateName(name);
  return (
    <Box
      sx={{
        width: 48,
        height: 48,
        background: "#d4d8e1",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 700,
        fontSize: 18,
      }}
    >
      <span>{abbrName}</span>
    </Box>
  );
};

export default RecipientAvatar;

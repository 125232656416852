export const FormatterService = {
  fmtThousands(value: string) {
    const [v0, ...v1] = value.split(".");
    return [
      v0.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      ...v1,
    ].join(".");
  },
  inputDecimal(
    value: string,
    { maxInt = 12, maxDec = 2 }: { maxInt?: number; maxDec?: number } = {}
  ): string {
    const sep = value.indexOf(".");
    return sep < 0
      ? this.fmtThousands(value.substring(0, maxInt))
      : this.fmtThousands(value.substring(0, Math.min(maxInt, sep))) +
          "." +
          value
            .substring(sep + 1)
            .replace(/\D/g, "")
            .substring(0, maxDec);
  },
  toNumber(value: string | number) {
    return typeof value === "number"
      ? value
      : Number(value.replace(/[^\d.]/g, ""));
  },
  formatDecimal(value: string | number | undefined, dec: number = 2) {
    return value === undefined || value === ""
      ? value
      : this.fmtThousands(this.toNumber(value).toFixed(dec));
  },
};

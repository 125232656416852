import { SvgIcon } from "@mui/material";
import React from "react";

type IconProps = React.ComponentProps<typeof SvgIcon>;
const AlertIcon: React.FC<IconProps> = (props) => {
  return (
    <SvgIcon width="49" height="49" viewBox="0 0 49 49" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4759 5.54121C22.3745 5.03531 23.3883 4.76953 24.4195 4.76953C25.4507 4.76953 26.4645 5.03531 27.3631 5.54121C28.2616 6.04712 29.0147 6.77608 29.5495 7.65776L29.5552 7.66726L29.5552 7.66728L46.4952 35.9473L46.5115 35.9749C47.0354 36.8822 47.3126 37.9108 47.3155 38.9585C47.3184 40.0061 47.047 41.0363 46.5282 41.9465C46.0094 42.8567 45.2613 43.6151 44.3583 44.1465C43.4554 44.6778 42.4291 44.9634 41.3815 44.9749L41.3595 44.975H7.47948L7.45749 44.9749C6.40988 44.9634 5.38356 44.6778 4.48062 44.1465C3.57767 43.6151 2.82958 42.8567 2.31077 41.9465C1.79197 41.0363 1.52053 40.0061 1.52346 38.9585C1.52639 37.9108 1.8036 36.8822 2.32749 35.9749L2.34374 35.9473L19.2837 7.66728L20.9994 8.695L19.2895 7.65776C19.8243 6.77608 20.5773 6.04712 21.4759 5.54121ZM22.7119 9.72829L5.78485 37.9867C5.61449 38.2863 5.52441 38.6249 5.52345 38.9697C5.52247 39.3189 5.61295 39.6623 5.78588 39.9657C5.95882 40.2691 6.20818 40.5219 6.50916 40.699C6.80751 40.8745 7.14626 40.9696 7.49227 40.975H41.3467C41.6927 40.9696 42.0314 40.8745 42.3298 40.699C42.6308 40.5219 42.8801 40.2691 43.0531 39.9657C43.226 39.6623 43.3165 39.3189 43.3155 38.9697C43.3145 38.6249 43.2245 38.2863 43.0542 37.9868L26.1295 9.73228L26.1271 9.72829C25.9489 9.43619 25.6988 9.19463 25.4007 9.02676C25.1011 8.85812 24.7632 8.76953 24.4195 8.76953C24.0757 8.76953 23.7378 8.85812 23.4383 9.02676C23.1401 9.19463 22.89 9.43619 22.7119 9.72829ZM24.4141 16.9727C25.5186 16.9727 26.4141 17.8681 26.4141 18.9727V26.9727C26.4141 28.0772 25.5186 28.9727 24.4141 28.9727C23.3095 28.9727 22.4141 28.0772 22.4141 26.9727V18.9727C22.4141 17.8681 23.3095 16.9727 24.4141 16.9727ZM24.4141 32.9727C23.3095 32.9727 22.4141 33.8681 22.4141 34.9727C22.4141 36.0772 23.3095 36.9727 24.4141 36.9727H24.4341C25.5386 36.9727 26.4341 36.0772 26.4341 34.9727C26.4341 33.8681 25.5386 32.9727 24.4341 32.9727H24.4141Z"
        fill="#9DA2A5"
      />
    </SvgIcon>
  );
};

export default AlertIcon;

import { Box } from "@mui/material";

type TArgs = {
  [key in "header" | "footer" | "children"]?: React.ReactNode;
} & {
  sx?: React.ComponentProps<typeof Box>["sx"];
};

export const FullPageTemplate = ({ header, footer, children, sx }: TArgs) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      width: "100vw",
      overflow: "hidden",
    }}
  >
    <Box>{header}</Box>
    <Box sx={{ flex: 1, overflow: "none", overflowY: "auto", ...sx }}>
      {children}
    </Box>
    <Box>{footer}</Box>
  </Box>
);

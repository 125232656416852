import { Box, Button, IconButton, Typography } from "@mui/material";
import { AlertDialog } from "ad/molecules/AlertDialog";
import { t, tmap } from "i18n";
import React, { useEffect } from "react";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";
import { ReactComponent as CheckIcon } from "../../assets/images/check-circle.svg";
import { TWelcomeAlertVariant } from "./types";

type TProps = {
  open?: boolean;
  onClose?: () => void;
  onViewDetails?: () => void;
  variant: TWelcomeAlertVariant;
  firstTransferId?: string;
};
export const WelcomeAlert: React.FC<TProps> = ({
  open,
  onClose,
  onViewDetails,
  variant,
  firstTransferId,
}): JSX.Element => {
  useEffect(() => WebviewService.onOpenModal(WV_MESSAGE.MONEY), []);

  return (
    <AlertDialog
      open={!!open}
      footer={
        <Box sx={{ width: "100%", px: 4 }}>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              my: 2,
              textTransform: "none",
            }}
            onClick={onClose}
          >
            Got it
          </Button>
          {firstTransferId ? (
            <Button
              size="large"
              variant="text"
              color="primary"
              onClick={onViewDetails}
              sx={{
                whiteSpace: "nowrap",
                marginTop: 0.25,
                width: "90%",
                fontSize: "0.8rem",
              }}
            >
              View Payment Details
            </Button>
          ) : null}
        </Box>
      }
    >
      <Box sx={{ textAlign: "center" }}>
        <IconButton>
          <CheckIcon />
        </IconButton>
        <Typography variant="h4" sx={{ paddingY: 1 }}>
          {t(`money.welcome_title_${variant}`)}
        </Typography>
        {tmap(`money.welcome_msg_${variant}`).map((msg, i) => (
          <Typography
            key={i}
            variant="body2"
            sx={{ color: "#3B464B", paddingY: 1 }}
          >
            {msg}
          </Typography>
        ))}
      </Box>
    </AlertDialog>
  );
};

import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getWiseProfile } from "services/APIService/Accounts/api";
import {
  CreateQuoteAnominousParam,
  createQuoteWithProfile,
} from "services/APIService/Quotes/QuotesApi";
import { WiseQuote } from "services/APIService/Quotes/types";
import { ConfigService } from "services/ConfigService";
import { postWiseCode } from "./functions";

type TStatus = "created" | undefined;

export const usePersonalPostLogin = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code");
  const [status, setStatus] = useState<TStatus>();
  const quoteRef = useRef<WiseQuote>();

  const wiseOauth = useCallback(async (): Promise<TStatus> => {
    if (!code) throw new Error("code is required");
    const redirect_uri = ConfigService.postLoginUri;
    const res = await postWiseCode({ code, redirect_uri });
    if ("error" in res) {
      return undefined;
    }
    const out: TStatus =
      `${res.data.is_created || "false"}` === "false" ? "created" : undefined;
    setStatus(out);
    return out;
  }, [code]);

  const updateQuote = useCallback(async () => {
    const loginData = await getWiseProfile();
    const createQuoteParam = JSON.parse(
      localStorage.getItem("quoteParams")!
    ) as CreateQuoteAnominousParam;
    quoteRef.current = await createQuoteWithProfile({
      ...createQuoteParam,
      profile_id: loginData.profile_id,
    });
  }, []);

  const goRecipients = async () => {
    const loginData = await getWiseProfile();
    const quote = quoteRef.current;
    if (quote) {
      navigate(
        `/recipients/list?quote_id=${quote.quote_id}&profile_id=${loginData.profile_id}`
      );
    }
  };

  const createWiseProfileAndSyncQuote = async () => {
    const res = await wiseOauth();
    await updateQuote();
    if (res === undefined) await goRecipients();
  };

  useEffect(() => {
    createWiseProfileAndSyncQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    code,
    status,
    onNext: () => {
      goRecipients();
    },
  };
};

import { TResource } from "../types";

export const personal: TResource = {
  en: {
    content_title: "Personal Information Shared with Wise",
    content_body: [
      "We share your personal information with Wise for the purposes of opening your Wise account and only as required to fulfill any money transfer requirements you initiate.",
      "This information will generally include, but may not be limited to your full name, email address, the last 4 digits of your SSN, date of birth, and phone number.",
    ],
    checking_body_1:
      "We see you already have a Wise account tied to this email address.",
    checking_body_2:
      "Continue below to proceed with your existing Wise account.",
    connect: "Connect your Wise account",
    or: "Or",
    create_new: "Create a New Account",
    pre_login: "Taking you to Wise to log in...",
    pre_create: "Taking you to Wise...",
    login_title: "Welcome back.",
    login_email: "Your email address",
    login_passwd: "Your password",
    login_action: "Log in",
    post_title: "Account Created!",
    post_message: "Thank you for submitting verification documentation.",
    post_notify:
      "Once you send a transfer, we will begin the verification process and notify you when it is complete.",
    post_continue: "Continue",
  },
};

import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";
import { PersonalPageContext } from "./PersonalPageContext";
import { usePersonalPage } from "./usePersonalPage";

const PersonalPage = () => {
  const { contextValue } = usePersonalPage();
  useEffect(() => WebviewService.onOpenPage(WV_MESSAGE.PERSONAL_MSG), []);
  return (
    <PersonalPageContext.Provider value={contextValue}>
      <Outlet />
    </PersonalPageContext.Provider>
  );
};

export default PersonalPage;

import { WiseUserProvider } from "hooks/useWiseUser";
import AccountPage from "pages/AccountPage";
import { AccountLinkModal } from "pages/AccountPage/AccountLinkModal";
import { AccountPostLinkModal } from "pages/AccountPage/AccountPostLinkModal";
import { AccountRelinkModal } from "pages/AccountPage/AccountRelinkModal";
import FailedPage from "pages/FailedPages/FailedPage";
import { MTransferDetails } from "pages/MobileWebviews/MTransferDetails";
import MoneyPage from "pages/MoneyPage";
import { MoneyCurrencyModal } from "pages/MoneyPage/MoneyCurrencyModal";
import PersonalPage from "pages/PersonalPage";
import { PersonalCheckingModal } from "pages/PersonalPage/PersonalCheckingModal";
import { PersonalInformationPage } from "pages/PersonalPage/PersonalInformationPage";
import { PersonalPostLogin } from "pages/PersonalPage/PersonalPostLogin";
import { PersonalPreCreateModal } from "pages/PersonalPage/PersonalPreCreateModal";
import PersonalPreLoginModal from "pages/PersonalPage/PersonalPreLoginModal";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NoMatch } from "./NoMatch";
const TransactionPage = React.lazy(() => import("../../pages/TransactionPage"));
const MangeRecipientsPage = React.lazy(
  () => import("../../pages/MangeRecipientsPage")
);

export const Routing = () => {
  return (
    <WiseUserProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/money" />} />
        {/* <Route path="/:quoteId/transaction/*" element={<TransactionPage />} /> */}
        <Route path="/transaction/*" element={<TransactionPage />} />
        <Route path="/recipients/*" element={<MangeRecipientsPage />} />
        <Route path="/m/transfer-details" element={<MTransferDetails />} />

        <Route path="/account" element={<AccountPage />}>
          <Route path="link" element={<AccountLinkModal open />} />
          <Route path="relink" element={<AccountRelinkModal open />} />
          <Route path="post-link" element={<AccountPostLinkModal open />} />
        </Route>
        <Route path="/personal" element={<PersonalPage />}>
          <Route path="checking" element={<PersonalCheckingModal open />} />
          <Route path="pre-login" element={<PersonalPreLoginModal open />} />
          <Route path="pre-create" element={<PersonalPreCreateModal open />} />
          <Route path="post-login" element={<PersonalPostLogin open />} />
        </Route>
        <Route path="/personal-info" element={<PersonalInformationPage />} />
        <Route path="/money" element={<MoneyPage />}>
          <Route path="currency" element={<MoneyCurrencyModal open />} />
        </Route>
        <Route path="/failed/:reason" element={<FailedPage />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </WiseUserProvider>
  );
};

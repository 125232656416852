import * as React from "react";
import { Box, Typography } from "@mui/material";
import RecipientAvatar from "app/components/RecipientAvatar/RecipientAvatar";

function abbreviateName(name: string): string {
  const [firstName, lastName] = (name || "").split(" ");
  if (!lastName) {
    return firstName.slice(0, 2).toUpperCase();
  }
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
}

interface RecipientInfoProps {
  name: string;
  recipientId: string;
}

const RecipientInfo: React.FC<RecipientInfoProps> = ({
  name,
  recipientId,
}): JSX.Element => {
  const abbrName = abbreviateName(name);
  return (
    <Box
      sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
    >
      <RecipientAvatar name={abbrName} />
      <Box sx={{ ml: 1 }}>
        <Typography variant="h3">{name}</Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {recipientId}
        </Typography>
      </Box>
    </Box>
  );
};

export default RecipientInfo;

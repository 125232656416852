import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { WiseQuote } from "../Quotes/types";
import { WiseRecipient } from "../Recipients/types";
import { PayfareResponse } from "../types";
import { buildGeneralAxiosConfig } from "../utils";
import {
  WiseTransaction,
  WiseTransferDetail,
  WiseTransferDetailResponseData,
  WiseTransferRequirementsResponse,
} from "./types";

type CreatingTransferParams = {
  fields?: { [key: string]: any };
  recipientId: number;
  quoteId: string;
};

type CreateTransferResponseData = {
  wise_transfer: WiseTransaction;
};

export async function createTransfer({
  fields,
  recipientId,
  quoteId,
}: CreatingTransferParams) {
  const endPoint = "/v6/international-transfer/transfer";
  const customerTransactionId = uuidv4();
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestData = {
    ...axiosDefaultConfig.params,
    recipient_id: recipientId,
    transaction_id: customerTransactionId,
    quote_uuid: quoteId,
    ...fields,
  };
  return axios
    .post<PayfareResponse<CreateTransferResponseData>>(endPoint, requestData, {
      headers: axiosDefaultConfig.headers,
    })
    .then((res) => res.data.data.wise_transfer);
}

type CheckRequirementFieldParams = {
  recipient_id: number;
  user_id: string;
  quote_uuid: string;
};
export async function checkRequirementFields(
  params: CheckRequirementFieldParams
) {
  const endPoint = `/v6/international-transfer/transfer/requirements`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestData = {
    ...axiosDefaultConfig.params,
    ...params,
  };
  return axios
    .post<PayfareResponse<WiseTransferRequirementsResponse>>(
      endPoint,
      requestData,
      {
        headers: axiosDefaultConfig.headers,
      }
    )
    .then((res) => res.data.data.wise_transfer_evaluation);
}

export type GetWiseTransferDetailResponse = {
  transfer: WiseTransferDetail;
  quote?: WiseQuote;
  recipient?: WiseRecipient;
};
export async function getWiseTransferDetail(
  transfer_id: number | string,
  with_quote_data: boolean = false,
  with_recipient_data: boolean = false
): Promise<GetWiseTransferDetailResponse> {
  const endPoint = `/v6/international-transfer/transfer`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestData = {
    ...axiosDefaultConfig.params,
    with_quote: with_quote_data ? "true" : undefined,
    with_recipient: with_recipient_data ? "true" : undefined,
    transfer_id,
  };

  const responseData = await axios
    .get<PayfareResponse<WiseTransferDetailResponseData>>(endPoint, {
      params: requestData,
      headers: axiosDefaultConfig.headers,
    })
    .then((res) => res.data.data);

  const {
    wise_transfer: wiseTransfer,
    wise_quote: quote,
    wise_recipient: recipient,
  } = responseData;
  return { transfer: wiseTransfer, quote, recipient };
}

export function isTransferFailed(wiseTransfer: WiseTransferDetail): boolean {
  const { status } = wiseTransfer;
  return !!/-failed$/.exec(status);
}

export async function downloadRecipient(transfer_id: number | string) {
  const endPoint = `/v6/international-transfer/transfer/pdf-receipt`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestData = {
    ...axiosDefaultConfig.params,
    transfer_id,
  };
  const res = await axios.get(endPoint, {
    params: requestData,
    headers: axiosDefaultConfig.headers,
    responseType: "blob",
  });
  const URL = window.URL || window.webkitURL;
  const downloadUrl = URL.createObjectURL(res.data);
  return downloadUrl;
}

type TransferTimeLineResponse = {
  wise_transfer_timeline: Array<{
    status: string;
    occurred_at: string;
  }>;
};
export async function transferTimeLine(transfer_id: number | string) {
  const endPoint = `/v6/international-transfer/transfer/timeline`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestData = {
    ...axiosDefaultConfig.params,
    transfer_id,
  };

  const res = await axios.get<PayfareResponse<TransferTimeLineResponse>>(
    endPoint,
    {
      params: requestData,
      headers: axiosDefaultConfig.headers,
    }
  );
  return res.data?.data?.wise_transfer_timeline || [];
}

import Check from "@mui/icons-material/Check";
import { Box, Button, Icon, Typography } from "@mui/material";
import { PageDialog } from "ad/molecules/PageDialog";
import { t, tmap } from "i18n";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";
import { AccountPageContext } from "./AccountPageContext";

type TProps = { open?: boolean };

const LinkTermItem: React.FC<{ link?: string | null; text: string }> = ({
  link,
  text,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!link) return false;
    WebviewService.onOpenPage(WV_MESSAGE.ACCOUNT_LINK_LINK_CLICK);
    if (/http/.exec(link)) {
      window.location.href = link;
    } else {
      navigate(link);
    }
  };

  const defaultStyle = {
    fontSize: "11px",
    lineHeight: "13px",
    color: "516078",
  };

  const styles = link
    ? { ...defaultStyle, textDecoration: "underline" }
    : defaultStyle;
  return (
    <Typography component="span" onClick={handleClick} sx={styles}>
      {text}
    </Typography>
  );
};

export const AccountLinkModal = ({ open }: TProps) => {
  const { onCreate, onLogin } = useContext(AccountPageContext);

  useEffect(() => WebviewService.onOpenModal(WV_MESSAGE.ACCOUNT), []);

  return (
    <PageDialog
      open={!!open}
      footer={
        <>
          <Typography
            variant="caption"
            fontSize="0.7rem"
            sx={{ margin: 2, textAlign: "center", color: "#516078" }}
          >
            {tmap("account.link_terms").map((item, i) => {
              const { text, link } = item;
              return <LinkTermItem key={i} text={text} link={link} />;
            })}
          </Typography>
          <Button
            onClick={onCreate}
            variant="contained"
            color="secondary"
            sx={{
              borderRadius: 40,
              textTransform: "none",
              marginY: 1,
              paddingY: 2,
              width: "90%",
              fontSize: "1rem",
              fontWeight: "700",
            }}
          >{`${t("account.create")}`}</Button>
          <Button
            onClick={onLogin}
            variant="text"
            sx={{ textTransform: "none", marginBottom: 4 }}
          >
            <Typography variant="body2" sx={{ color: "#0C2232" }}>
              {`${t("account.or")}`}&nbsp;
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{ color: "#0C2232", textDecoration: "underline" }}
            >
              {`${t("account.login")}`}
            </Typography>
          </Button>
        </>
      }
    >
      <img
        src="/paidapp_wise_1-2.png"
        alt="logo"
        style={{
          maxWidth: "100%",
          objectFit: "contain",
          padding: "0 10%",
          textAlign: "center",
        }}
      />
      <Typography variant="h4" sx={{ marginTop: 5, marginBottom: 7, textAlign: "center" }}>{`${t(
        "account.title"
      )}`}</Typography>
      {tmap("account.link_body").map((item, i) => (
        <Box key={i} sx={{ display: "flex", flexDirection: "row", marginY: 1 }}>
          <Icon sx={{ color: "#377D31", marginX: 1 }}>
            <Check />
          </Icon>
          <Box>
            <Typography
              variant="body2"
              fontWeight={700}
              sx={{ color: "#000000" }}
            >{`${item.title}`}</Typography>
            <Typography
              variant="body2"
              fontSize="0.9rem"
              sx={{ color: "#3B464B", mt: 0.5 }}
            >{`${item.text}`}</Typography>
          </Box>
        </Box>
      ))}
    </PageDialog>
  );
};

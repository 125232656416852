import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./res";

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  nsSeparator: "::",
  interpolation: {
    escapeValue: false,
  },
});

/** default t() for string translations */
export const t = (
  arg: Parameters<typeof i18next.t>[0],
  keys?: { [key: string]: string | number | undefined }
): string | undefined => i18next.t(arg, keys);

/** mapped translation for paragraphs */
export const tmap = (
  arg: Parameters<typeof i18next.t>[0],
  keys?: { [key: string]: string | number | undefined }
): any[] => {
  const results = i18next.t(arg, { ...keys, returnObjects: true });
  return Array.isArray(results) ? results : [results];
};

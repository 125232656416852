import { WiseQuoteProvider } from "hooks/useQuoteStore";
import { WiseRecipientProvider } from "hooks/useRecipientStore";
import { TransferContainer } from "hooks/useTransfer";
import LoadingPage from "ad/molecules/LoadingPage";
import PaymentDetails from "pages/TransactionPage/PaymentDetails";

export const MTransferDetails: React.FC = () => {
  return (
    <TransferContainer loadingComponent={<LoadingPage />}>
      <WiseQuoteProvider loaddingComponent={<LoadingPage />}>
        <WiseRecipientProvider loaddingComponent={<LoadingPage />}>
          <PaymentDetails />
        </WiseRecipientProvider>
      </WiseQuoteProvider>
    </TransferContainer>
  );
};

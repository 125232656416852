import axios from "axios";
import { ConfigService } from "services/ConfigService";
import {
  ENextStep,
  TBishopEnv,
  TCreateNextStep,
  TCreateProfile,
  TErr,
  TErrData,
  TEvalAmount,
  TGetBalance,
  TGetCards,
  TGetCurrencies,
  TGetLimits,
  TSendWiseCode,
} from "./types";
import { bishopHeaders, commomHeaders, isValidStatus } from "./utils";

export const APIService = {
  /** balance (cards) */
  async getCards(
    params: TGetCards["IN"],
    env: TBishopEnv
  ): Promise<TGetCards["OUT"] | TErr> {
    const method = "getCards";
    const url = `${ConfigService.bishop}/v6/users/${env.user_id}/cards`;
    try {
      const out = await axios.get<TGetCards["OUT"]>(url, {
        headers: { ...commomHeaders(), ...bishopHeaders(env) },
        params,
      });
      return isValidStatus(out.status)
        ? out.data
        : { error: "invalid", method };
    } catch (err) {
      return { error: String(err), method, catch: true };
    }
  },

  /** balance */
  async getBalance(
    params: TGetBalance["IN"],
    env: TBishopEnv
  ): Promise<TGetBalance["OUT"] | TErr> {
    const method = "getBalance";
    const url = `${ConfigService.bishop}/v6/users/${env.user_id}/cards/${params.card_ting}/balance`;
    try {
      const out = await axios.get<TGetBalance["OUT"]>(url, {
        headers: { ...commomHeaders(), ...bishopHeaders(env) },
        params,
      });
      return isValidStatus(out.status)
        ? out.data
        : { error: "invalid", method };
    } catch (err) {
      return { error: String(err), method, catch: true };
    }
  },

  /** limits */
  async getLimits(
    params: TGetLimits["IN"],
    env: TBishopEnv
  ): Promise<TGetLimits["OUT"] | TErr> {
    const method = "getBalance";
    const url = `${ConfigService.bishop}/v6/international-transfer/transfer/limits`;
    try {
      const out = await axios.get<TGetLimits["OUT"]>(url, {
        headers: { ...commomHeaders(), ...bishopHeaders(env) },
        params,
      });
      return isValidStatus(out.status)
        ? out.data
        : { error: "invalid", method };
    } catch (err) {
      return { error: String(err), method, catch: true };
    }
  },

  /** eval source/targed amount and fees */
  async evalAmount(
    data: TEvalAmount["IN"],
    env: TBishopEnv
  ): Promise<TEvalAmount["OUT"] | TErr> {
    const method = "evalAmount";
    const url = `${ConfigService.bishop}/v6/international-transfer/quote/anonymous`;
    try {
      const out = await axios.post<TEvalAmount["OUT"]>(url, data, {
        headers: { ...commomHeaders(), ...bishopHeaders(env) },
      });
      return isValidStatus(out.status)
        ? out.data
        : { error: "invalid", method };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errData = err.response?.data as TErrData;
        return { error: errData?.errors?.[0]?.message || String(err), method };
      }
      return { error: String(err), method, catch: true };
    }
  },

  /** get avaliable currencies */
  async getCurrencies(
    params: TGetCurrencies["IN"],
    env: TBishopEnv
  ): Promise<TGetCurrencies["OUT"] | TErr> {
    const method = "getCurrencies";
    const url = `${ConfigService.bishop}/v6/international-transfer/currencies`;
    try {
      const out = await axios.get<TGetCurrencies["OUT"]>(url, {
        headers: { ...commomHeaders(), ...bishopHeaders(env) },
        params,
      });
      return isValidStatus(out.status)
        ? out.data
        : { error: "invalid", method };
    } catch (err) {
      return { error: String(err), method, catch: true };
    }
  },

  /** send post login wise code */
  async sendWiseCode(
    data: TSendWiseCode["IN"],
    env: TBishopEnv
  ): Promise<TSendWiseCode["OUT"] | TErr> {
    const method = "sendWiseCode";
    const url = `${ConfigService.bishop}/v6/international-transfer/oauth/wise`;
    try {
      const out = await axios.post<TSendWiseCode["OUT"]>(url, data, {
        headers: { ...commomHeaders(), ...bishopHeaders(env) },
      });
      return isValidStatus(out.status)
        ? out.data
        : { error: "invalid", method };
    } catch (err) {
      return { error: String(err), method, catch: true };
    }
  },

  /** send post login wise code */
  async createProfile(
    data: TCreateProfile["IN"],
    nextStep: ENextStep | undefined,
    env: TBishopEnv
  ): Promise<TCreateProfile["OUT"] | TErr> {
    const method = "sendWiseCode";
    const url =
      nextStep === ENextStep.CREATE_PROFILE
        ? `${ConfigService.bishop}/v6/international-transfer/user/create-profile`
        : `${ConfigService.bishop}/v6/international-transfer/user/create`;
    try {
      const middle = await axios.post<TCreateProfile["MIDDLE"]>(url, data, {
        headers: { ...commomHeaders(), ...bishopHeaders(env) },
      });
      return isValidStatus(middle.status)
        ? {
            next_step:
              middle.data.data.next_step === ENextStep.REDIRECT_LINK
                ? ENextStep.REDIRECT_LINK
                : ENextStep.RECLAIM_LINK,
          }
        : { error: "invalid", method };
    } catch (err) {
      return { error: String(err), method, catch: true };
    }
  },

  async createNextStep(
    data: TCreateNextStep["IN"],
    nextStep: ENextStep,
    env: TBishopEnv
  ): Promise<TCreateNextStep["OUT"] | TErr> {
    const method = "sendWiseCode";
    const url =
      nextStep === ENextStep.REDIRECT_LINK
        ? `${ConfigService.bishop}/v6/international-transfer/redirect-link`
        : `${ConfigService.bishop}/v6/international-transfer/reclaim-link`;
    try {
      const middle = await axios.post<TCreateNextStep["MIDDLE"]>(url, data, {
        headers: { ...commomHeaders(), ...bishopHeaders(env) },
      });
      return isValidStatus(middle.status)
        ? {
            uri:
              nextStep === ENextStep.REDIRECT_LINK
                ? middle.data.data.redirect_link
                : middle.data.data.reclaim_link,
          }
        : { error: "invalid", method };
    } catch (err) {
      return { error: String(err), method, catch: true };
    }
  },
};

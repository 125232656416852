import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import { Routing } from "./routes/Routing";
import { ThemeSettings } from "./ThemeSettings";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { OneTimeTokenWrapper } from "hooks/OneTimeTokenWrapper";
import { getParams } from "./getParams";

export const App = () => {
  const urlParams = getParams();
  const theme = ThemeSettings();

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <OneTimeTokenWrapper {...urlParams}>
              <Routing />
            </OneTimeTokenWrapper>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

import { styled, Box, InputBase } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
const Wrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius * 50,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  border: "1px solid #CCCCCC",
}));

const IconWrapper = styled("div")(({ theme }) => {
  return {
    position: "absolute",
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(6),
  };
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 2, 1.5, 6),
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

type SearchInputProps = { inputProps: React.ComponentProps<typeof InputBase> };
const SearchInput: React.FC<SearchInputProps> = ({ inputProps }) => {
  return (
    <Wrapper>
      <IconWrapper>
        <SearchIcon sx={{ color: "#377D31" }} />
      </IconWrapper>
      <StyledInputBase {...inputProps} />
    </Wrapper>
  );
};

export default SearchInput;

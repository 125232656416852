import { Typography } from "@mui/material";
import { VerticalCenteredBox } from "ad/atoms/VerticalCenteredBox";
import { PageDialog } from "ad/molecules/PageDialog";
import { useRequest } from "ahooks";
import LoaderIcon from "app/components/icons/LoaderIcon";
import { t } from "i18n";
import { getWiseLoginLink } from "services/APIService/Accounts/api";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";

type TProps = { open?: boolean };

const PersonalPreLoginModal = ({ open }: TProps) => {
  const { data: redirectLink, loading } = useRequest(getWiseLoginLink);

  if (!loading && redirectLink) {
    WebviewService.onOpenPage(WV_MESSAGE.PERSONAL);
    window.location.href = redirectLink;
    return null;
  }

  return (
    <PageDialog open={!!open}>
      <VerticalCenteredBox sx={{ alignItems: "center" }}>
        <LoaderIcon sx={{ animation: "spin 2s linear infinite" }} />
        <Typography variant="subtitle2" sx={{ fontWeight: 700, mt: 3 }}>
          {`${t("personal.pre_login")}`}
        </Typography>
      </VerticalCenteredBox>
    </PageDialog>
  );
};

export default PersonalPreLoginModal;

import { TResource } from "../types";

export const failed: TResource = {
  en: {
    default_message: "Something Went Wrong",
    default_description:
      "Sorry, we can’t process your request. <br/> Please try again.",
    creating_profile_failed_message: "Connecting Wise account failed",
  },
};

import { useNavigate } from "react-router-dom";
import { createWiseUser } from "services/APIService/Accounts/api";
import { TAccountPageContext } from "./AccountPageContext";

const ROUTE_PERSONAL = "/account";
const ROUTE_CREATE = "/personal/pre-create";
const ROUTE_CHECKING = "/personal/checking";
const ROUTE_LOGIN = "/personal/pre-login";

export const useAccountPage = () => {
  const navigate = useNavigate();

  const onBack = () => navigate(ROUTE_PERSONAL);

  const onCreate = async () => {
    const createData = await createWiseUser();
    const { email_exist, email } = createData;
    if (email_exist) {
      navigate(`${ROUTE_CHECKING}?email=${encodeURIComponent(email || "")}`);
    } else {
      navigate(ROUTE_CREATE);
    }
  };

  const onLogin = () => navigate(ROUTE_LOGIN);

  const contextValue: TAccountPageContext = {
    onBack,
    onCreate,
    onLogin,
  };

  return {
    contextValue,
  };
};

import { createTheme } from "@mui/material/styles";

import components from "./theme/Override";
import typography from "./theme/Typography";
import palette from "./theme/Pallete";

export const ThemeSettings = () => {
  const theme = createTheme({
    typography,
    palette,
    components,
  });
  return theme;
};

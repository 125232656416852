import { Box, Button, Typography } from "@mui/material";
import { PageDialog } from "ad/molecules/PageDialog";
import { useRequest } from "ahooks";
import { t } from "i18n";
import LoadingPage from "ad/molecules/LoadingPage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRedirectLinkData } from "services/APIService/Accounts/api";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";

type TProps = { open?: boolean };

const ROUTE_LOGIN = "/personal/pre-login";

export const AccountRelinkModal = ({ open }: TProps) => {
  const navigate = useNavigate();
  const { data, loading } = useRequest(getRedirectLinkData);

  const handleConnect = () => {
    if (data?.redirect_link) {
      window.location.href = data.redirect_link;
    } else {
      navigate(ROUTE_LOGIN);
    }
  };

  useEffect(() => WebviewService.onOpenModal(WV_MESSAGE.ACCOUNT), []);

  if (loading) return <LoadingPage />;

  return (
    <PageDialog
      open={!!open}
      footer={
        <>
          <Button
            onClick={handleConnect}
            variant="contained"
            color="secondary"
            sx={{
              borderRadius: 40,
              textTransform: "none",
              marginY: 1,
              paddingY: 2,
              width: "90%",
              fontSize: "1rem",
              fontWeight: "700",
            }}
          >{`${t("account.relink_connect")}`}</Button>
        </>
      }
    >
      <Box sx={{ textAlign: "center", marginX: "10%" }}>
        <Box sx={{ my: 5 }}>
          <img
            src="/paidapp_wise_1-2.png"
            alt="logo"
            style={{ maxWidth: "100%", objectFit: "contain" }}
          />
        </Box>
        <Typography
          variant="body2"
          className="pf-title"
          sx={{ paddingY: 1 }}
        >{`${t("account.relink_body_1")}`}</Typography>
        <Typography variant="body2" className="pf-item" sx={{ paddingY: 1 }}>
          {data?.email}
        </Typography>
        <Typography
          variant="body2"
          className="pf-title"
          sx={{ paddingY: 1 }}
        >{`${t("account.relink_body_2")}`}</Typography>
      </Box>
    </PageDialog>
  );
};

import { Box, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormatterService } from "services/FormatterService";
import { TLimit } from "./types";

export const LimitListItem = ({
  item: { label, remaining, total },
}: {
  item: TLimit;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", my: 1 }}
      className="pf-limit-item"
    >
      <Typography sx={{ alignSelf: "flex-end" }}>
        <Typography
          component="span"
          variant="body2"
          className="pf-limit-remaining"
        >
          {`$${FormatterService.formatDecimal(remaining)} `}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          className="pf-limit-remaining-label"
        >
          {t("money.remaining")}
        </Typography>
      </Typography>
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={(100 * remaining) / total}
        sx={{ height: 8, borderRadius: 4 }}
        className="pf-limit-item"
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 0.5 }}>
        <Typography variant="body2" className="pf-limit-label">
          {label}
        </Typography>
        <Typography variant="body2" className="pf-limit-total">
          {`$${FormatterService.formatDecimal(total)}`}
        </Typography>
      </Box>
    </Box>
  );
};

import { List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TDetail } from "./types";

const SubtractConnector = () => (
  <svg height="40" width="20">
    <line x1="10" y1="0" x2="10" y2="15" stroke="#BEC3D2" strokeWidth="1" />
    <circle
      cx="10"
      cy="20"
      r="5"
      stroke="#BEC3D2"
      strokeWidth="1"
      fill="#BEC3D2"
    />
    <line x1="8" y1="20" x2="12" y2="20" stroke="#ffffff" strokeWidth="1" />
    <line x1="10" y1="25" x2="10" y2="40" stroke="#BEC3D2" strokeWidth="1" />
  </svg>
);

const MultiplyConnector = () => (
  <svg height="40" width="20">
    <line x1="10" y1="0" x2="10" y2="15" stroke="#BEC3D2" strokeWidth="1" />
    <circle
      cx="10"
      cy="20"
      r="5"
      stroke="#BEC3D2"
      strokeWidth="1"
      fill="#BEC3D2"
    />
    <line x1="8" y1="18" x2="12" y2="22" stroke="#ffffff" strokeWidth="1" />
    <line x1="8" y1="22" x2="12" y2="18" stroke="#ffffff" strokeWidth="1" />
    <line x1="10" y1="25" x2="10" y2="40" stroke="#BEC3D2" strokeWidth="1" />
  </svg>
);

export const DetailList = ({ list }: { list?: TDetail[] }) => {
  const { t } = useTranslation();
  return list?.length ? (
    <List dense>
      {list.map((item) => (
        <ListItem key={item.label} sx={{ py: 0 }}>
          {item.connector === "-" && <SubtractConnector />}
          {item.connector === "x" && <MultiplyConnector />}
          <ListItemText
            primary={t("money.detail", item)}
            primaryTypographyProps={{ fontSize: "11px", color: "#757575" }}
          />
        </ListItem>
      ))}
    </List>
  ) : null;
};

import axios, { AxiosError } from "axios";
import { TWelcomeAlertVariant } from "pages/MoneyPage/types";
import { ConfigService } from "services/ConfigService";
import { PayfareResponse } from "../types";
import { buildGeneralAxiosConfig } from "../utils";
import {
  CheckingWiseUserExistData,
  CreateWiseUserResponseData,
  LoginWiseReponseData,
  ReclaimLinkData,
  RedirectLinkData,
  ReturnedGetWiseProfile,
} from "./types";

export type UpgradeBishopTokenParams = {
  bibson?: string | null;
  ting: string | number | null;
  userId: string | number | null;
  oneTimeToken: string | null;
  langCode: string | null;
  channel: string | null;
  countryCode: string | null;
};

type BishopTokenResponseData = {
  bibson: string;
};
export async function upgradeBishopToken(
  upgradeTokenParams: UpgradeBishopTokenParams
): Promise<string> {
  const {
    userId,
    oneTimeToken,
    langCode: lang_code,
    channel,
    countryCode: country_code,
  } = upgradeTokenParams;
  const endPoint = `/v6/sessions/${userId}/upgrade`;

  const axiosDefaultConfig = buildGeneralAxiosConfig();
  return await axios
    .post<PayfareResponse<BishopTokenResponseData>>(
      endPoint,
      { channel, country_code, lang_code },
      {
        headers: {
          ...axiosDefaultConfig.headers,
          "x-bishop": oneTimeToken as string,
        },
      }
    )
    .then((res) => res.data.data.bibson);
}

export async function checkIfWiseUserAlredyExists(): Promise<CheckingWiseUserExistData> {
  const endPoint = `/v6/international-transfer/user/exist`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  try {
    return await axios
      .post<PayfareResponse<CheckingWiseUserExistData>>(
        endPoint,
        axiosDefaultConfig.params,
        {
          headers: axiosDefaultConfig.headers,
        }
      )
      .then((res) => res.data.data);
  } catch (error) {
    const { response } = error as AxiosError<
      PayfareResponse<CheckingWiseUserExistData>
    >;
    return (response?.data.errors?.at(0) as CheckingWiseUserExistData) || {};
  }
}

export function isWiseAccountAlreadyConnected(
  data?: CheckingWiseUserExistData
): boolean {
  const { have_wise_account, should_create_profile } = data || {};
  // Have to check false value because the response not alway return this data.
  return have_wise_account === true && should_create_profile === false;
}

export function isRequiredToCreateProfile(
  data?: CheckingWiseUserExistData
): boolean {
  const { have_wise_account, should_create_profile } = data || {};
  return !!(have_wise_account && should_create_profile);
}

export function isRequiredToCreateUser(
  data?: CheckingWiseUserExistData
): boolean {
  const { have_wise_account, should_create_profile } = data || {};
  return !have_wise_account && should_create_profile!;
}

export async function getWiseProfile(): Promise<ReturnedGetWiseProfile> {
  const endPoint = `/v6/international-transfer/user/login`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  try {
    return await axios
      .post<PayfareResponse<LoginWiseReponseData>>(
        endPoint,
        axiosDefaultConfig.params,
        {
          headers: axiosDefaultConfig.headers,
        }
      )
      .then((res) => {
        const { data } = res;
        const { wise_user_id, profile_id, first_transfer, kyc_state } =
          data.data;
        return { wise_user_id, profile_id, first_transfer, kyc_state };
      });
  } catch (err) {
    return {
      wise_user_id: 0,
      profile_id: 0,
    };
  }
}

export async function createWiseUser(): Promise<CreateWiseUserResponseData> {
  const endPoint = `/v6/international-transfer/user/create`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  return await axios
    .post<PayfareResponse<CreateWiseUserResponseData>>(
      endPoint,
      axiosDefaultConfig.params,
      {
        headers: axiosDefaultConfig.headers,
      }
    )
    .then((res) => res.data.data);
}

export async function createUserProfile(): Promise<boolean> {
  const endPoint = `/v6/international-transfer/user/create-profile`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  return await axios
    .post<PayfareResponse<string>>(endPoint, axiosDefaultConfig.params, {
      headers: axiosDefaultConfig.headers,
    })
    .then((res) => res.data.status === "success");
}

export async function getReclaimLink(): Promise<string> {
  const endPoint = `/v6/international-transfer/reclaim-link`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const redirect_uri = ConfigService.postLoginUri;
  return await axios
    .post<PayfareResponse<ReclaimLinkData>>(
      endPoint,
      { ...axiosDefaultConfig.params, redirect_uri },
      {
        headers: axiosDefaultConfig.headers,
      }
    )
    .then((res) => res.data.data.redirect_link);
}

export async function getRedirectLinkData(): Promise<RedirectLinkData> {
  const endPoint = `/v6/international-transfer/redirect-link`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const redirect_uri = ConfigService.postLoginUri;
  return await axios
    .post<PayfareResponse<RedirectLinkData>>(
      endPoint,
      { ...axiosDefaultConfig.params, redirect_uri },
      {
        headers: axiosDefaultConfig.headers,
      }
    )
    .then((res) => res.data.data);
}

export async function getWiseLoginLink(): Promise<string> {
  return await getRedirectLinkData().then((res) => res.redirect_link);
}

export async function assignWelcomeViewed(
  variant: TWelcomeAlertVariant
): Promise<void> {
  const endPoint = `/v6/international-transfer/user/modal-showing-status-update`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const modal_name = variant === "sent" ? "first_transfer" : "welcome";
  await axios
    .post<PayfareResponse<ReclaimLinkData>>(
      endPoint,
      { ...axiosDefaultConfig.params, modal_name },
      {
        headers: axiosDefaultConfig.headers,
      }
    )
    .then((res) => res.data.data.redirect_link);
}

import { Box, Button, IconButton, Typography } from "@mui/material";
import { AlertDialog } from "ad/molecules/AlertDialog";
import React, { useEffect } from "react";
import { TWebviewMessage, WebviewService } from "services/WebviewService";
import { ReactComponent as AlertIcon } from "../../assets/images/alert.svg";

type TProps = {
  open?: boolean;
  onClose?: () => void;
  message?: string;
  webviewMessage?: TWebviewMessage;
};
export const SimpleMessageAlert: React.FC<TProps> = ({
  open,
  onClose,
  message,
  webviewMessage,
}): JSX.Element => {
  useEffect(
    () =>
      open && webviewMessage
        ? WebviewService.onOpenModal(webviewMessage)
        : undefined,
    [open, webviewMessage]
  );
  return (
    <AlertDialog
      open={!!open}
      footer={
        <Button
          onClick={onClose}
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: 40,
            textTransform: "none",
            marginY: 2,
            paddingY: 1,
            width: "90%",
          }}
        >
          Got it
        </Button>
      }
    >
      <Box sx={{ textAlign: "center" }}>
        <IconButton>
          <AlertIcon />
        </IconButton>
        <Typography variant="h4" sx={{ paddingY: 1 }}>
          Oops!
        </Typography>
        <Typography variant="body2" sx={{ color: "#3B464B" }}>
          {message}
        </Typography>
      </Box>
    </AlertDialog>
  );
};

import { TResource } from "../types";

export const transaction: TResource = {
  en: {
    fee_include_exchange_rate:
      "{{fee, currency(USD)}} Wise fee included with an exchange rate of {{exchangeRate, number(minimumFractionDigits: 4)}}",
    amount: "{{amount, number(minimumFractionDigits: 2)}}",
    you_sent_x_amount_in_currency_to_y:
      "You sent {{x_amount, number(minimumFractionDigits: 2)}} {{currency}} to {{recipient_name}}",
    quote_expired: "Quote expired",
    processing_failed: "Processing failed",
    wise_transfer_id: "Wise ID: {{wiseTransferId}}",
    download_reciept: "Download Receipt",
    downloading: "Downloading...",
    transfer_status_incoming_payment_waiting: "On its way to Wise",
    transfer_status_incoming_payment_initiated: "On its way to Wise",
    transfer_status_processing: "Processing",
    transfer_status_funds_converted: "Processing",
    transfer_status_outgoing_payment_sent: "Sent",
    transfer_status_charged_back: "Charged back",
    transfer_status_cancelled: "Cancelled",
    transfer_status_cancelled_refund_processing: "Refund in progress",
    transfer_status_funds_refunded: "Refunded",
    transfer_status_bounced_back: "Bounced back",
    "transfer_status_transfer-funded": "Still with Payfare",
    "transfer_status_still-with-payfare": "Still with Payfare",
    "transfer_status_payfare-failure": "Failed",
    transfer_status_unknown: "Unknown",
    transfer_step_completed_at: "{{ completedDate, datetime }}",
    "create-on-wise-processing-failed": "Process on Wise failed",
    "create-on-wise-transition-temporary-failed": "Process on Wise failed",
    "check-fraud-transition-temporary-failed": "Checking Fraud failed",
    "fund-processing-failed": "Funding failed",
    "initiate-transition-failed": "Create transaction failed",
    "check-fraud-processing-failed": "Checking fraud failed",
    timeout: "Timeout",
    review_terms: [
      { text: "By sending this transfer you accept " },
      {
        text: "Wise's Customer Agreement",
        link: "https://wise.com/us/terms-of-use-tw-inc",
      },
      { text: " and " },
      {
        text: "Privacy Policy",
        link: "https://wise.com/us/privacy-policy-inc",
      },
      { text: ". Once sent, this transfer can not be cancelled." },
    ],
  },
};

import { Box, Button, IconButton, Typography } from "@mui/material";
import { AlertDialog } from "ad/molecules/AlertDialog";
import { t } from "i18n";
import React, { useEffect } from "react";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";
import { ReactComponent as AlertIcon } from "../../assets/images/alert.svg";

type TProps = {
  open?: boolean;
  onClose?: () => void;
  period?: number;
};
export const LimitReachedAlert: React.FC<TProps> = ({
  open,
  onClose,
  period,
}): JSX.Element => {
  useEffect(() => WebviewService.onOpenModal(WV_MESSAGE.MONEY), []);

  return (
    <AlertDialog
      open={!!open}
      footer={
        <Button
          onClick={onClose}
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: 40,
            textTransform: "none",
            marginY: 2,
            paddingY: 1,
            width: "90%",
          }}
        >
          Got it
        </Button>
      }
    >
      <Box sx={{ textAlign: "center" }}>
        <IconButton>
          <AlertIcon />
        </IconButton>
        <Typography variant="h4" sx={{ paddingY: 1 }}>
          Oops!
        </Typography>
        <Typography variant="body2" sx={{ color: "#3B464B" }}>
          You've reached your {t(`money.limit_${period}`)?.toLowerCase()} for
          now. Please try again later.
        </Typography>
      </Box>
    </AlertDialog>
  );
};

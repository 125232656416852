import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { FlagIcon } from "ad/atoms/FlagIcon";
import { TCurrency } from "./types";

type TArgs = {
  list?: TCurrency[];
  title?: string;
  onSelect?: (currency: TCurrency) => void;
};

export const CurrencyList = ({ list, title, onSelect }: TArgs) =>
  (list?.length || 0) > 0 ? (
    <>
      <Typography variant="body2" className="pf-title">
        {title}
      </Typography>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {list?.map((item) => {
          return (
            <ListItem
              key={`${item.label} ${item.code}`}
              secondaryAction={<div>{item.code}</div>}
              disablePadding
              onClick={() => onSelect?.(item)}
              sx={{ marginY: 0.75 }}
            >
              <ListItemButton dense sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <FlagIcon
                    rounded
                    country={item.code.substring(0, 2).toLowerCase()}
                    fontSize="large"
                    sx={{ mr: 1, ml: 0 }}
                  />
                </ListItemIcon>
                <ListItemText className="pf-item" primary={item.label} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  ) : null;

import { APIService } from "services/APIService";
import { TErr, TSendWiseCode } from "services/APIService/types";
import { StoreService } from "services/StoreService";

export const postWiseCode = async ({
  code,
  redirect_uri,
}: {
  code: string;
  redirect_uri: string;
}): Promise<TSendWiseCode["OUT"] | TErr> => {
  const bishop = StoreService.getBishop();
  const { user_id } = bishop.env;
  const out = await APIService.sendWiseCode(
    { ...bishop.data, code, user_id, redirect_uri },
    bishop.env
  );
  return out;
};

import { TResource } from "../types";

export const account: TResource = {
  en: {
    title: "We use Wise for fast, low-fee international transfers",
    link_body: [
      {
        title: "Real market exchange rate",
        text: "Send money at the real exchange rate with no hidden fees",
      },
      { title: "Transparent", text: "Clear, upfront fees every time" },
    ],
    link_terms: [
      { text: "By continuing you accept Wise's " },
      {
        text: "Terms of Use",
        link: "https://wise.com/us/terms-of-use-tw-inc",
      },
      { text: " and " },
      {
        text: "Privacy Policy",
        link: "https://wise.com/us/privacy-policy-inc",
      },
      { text: " and you consent to sharing certain " },
      { text: "personal information", link: "/personal-info" },
      { text: " with Wise in order to create your Wise account." },
    ],
    create: "Create a Wise account for me",
    or: "Already use Wise?",
    login: "Log In",
    relink_connect: "Connect your Wise account",
    relink_body_1:
      "We noticed that you've disconnected your Wise account for this email address.",
    relink_body_2: "Continue below to connect your Wise account.",
  },
};

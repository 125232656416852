import { useRequest } from "ahooks";
import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";

import { getRecipient } from "services/APIService/Recipients/RecipientsApi";
import { WiseRecipient } from "services/APIService/Recipients/types";
import { StoreService, STORE_KEYS } from "services/StoreService";

type WiseRecipientContextValue = {
  recipient: WiseRecipient;
  loading?: boolean;
};
const WiseRecipientContext = createContext<WiseRecipientContextValue>(
  {} as WiseRecipientContextValue
);

export const WiseRecipientProvider: React.FC<
  PropsWithChildren<{ loaddingComponent?: ReactElement }>
> = ({ children, loaddingComponent }) => {
  const [searchParam] = useSearchParams();

  const cachedRecipientId = useRef<number | string | null>(null);

  const recipientId =
    searchParam.get("recipient_id") ||
    StoreService.getString(STORE_KEYS.RECIPIENT_ID, null) ||
    cachedRecipientId.current;

  const { data, loading } = useRequest(
    async () => {
      if (!recipientId) return undefined;
      const recipient = await getRecipient(recipientId);
      cachedRecipientId.current = recipientId;
      return recipient;
    },
    { refreshDeps: [recipientId] }
  );

  if (loading || !data) return loaddingComponent ?? null;
  const value = {
    recipient: data,
    loading,
  };
  return (
    <WiseRecipientContext.Provider value={value}>
      {loaddingComponent && loading ? loaddingComponent : children}
    </WiseRecipientContext.Provider>
  );
};

export const useWiseRecipient = (): WiseRecipientContextValue => {
  const values = useContext(WiseRecipientContext);
  return values;
};

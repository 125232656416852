import { formatDistanceStrict, parseISO } from "date-fns";
import { useDebouncer } from "hooks/useDebouncer";
import { useWiseUser } from "hooks/useWiseUser";
import { t } from "i18n";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { assignWelcomeViewed } from "services/APIService/Accounts/api";
import { getWiseTransferDetail } from "services/APIService/Transfers/TransfersApi";
import { FormatterService } from "services/FormatterService";
import { clearQuoteIdOnStorage } from "services/StoreService";
import { evalAmount, evalReached, getBalance, getLimits } from "./functions";
import { TMoneyPageContext } from "./MoneyPageContext";
import { TCurrency, TData, TWelcomeAlertVariant } from "./types";
import { TCreateQuote, useCreateQuote } from "./useCreateQuote";

const ROUTE_MONEY = "/money";
const ROUTE_CURRENCY_MODAL = "/money/currency";
const ROUTE_VIEW_DETAILS = "/transaction/details";

const MIN_AMOUNT = 1;

type TWelcomeData = {
  variant: TWelcomeAlertVariant;
  open: boolean;
};

export const useMoneyPage = () => {
  const [data, setData] = useState<TData>({});
  const [welcomeData, setWelcomeData] = useState<TWelcomeData>();
  const { executeCreatingQuoteAsync, navigateNextPage } = useCreateQuote(data);
  const { profileId, firstTransferId, kycState } = useWiseUser();

  useEffect(() => {
    if (profileId) {
      const variant: TWelcomeAlertVariant | undefined = !!firstTransferId
        ? "sent"
        : kycState === "verified"
        ? "created"
        : kycState === "unverified"
        ? "verifying"
        : undefined;
      if (variant) {
        setWelcomeData((old) => old || { variant, open: true });
      }
    }
  }, [firstTransferId, kycState, profileId]);

  const beforeDebouncer = (value: TData): void => {
    if (value.changed) {
      setData((data) => ({
        ...data,
        detailList: undefined,
        arriveDistance: undefined,
      }));
      setData((data) => ({
        ...data,
        [value.changed === "send" ? "getMoney" : "sendMoney"]: "",
      }));
    }
  };

  const processAmount = useCallback(async (value: TData) => {
    const result = await evalAmount(value, MIN_AMOUNT);
    if (result) {
      if ("error" in result) {
        setData((data) => ({ ...data, alertMessage: result.error }));
      } else {
        const {
          data: {
            wise_quote: {
              source_amount,
              target_amount,
              fee,
              rate,
              estimated_delivery,
            },
          },
        } = result;
        const detailList: TData["detailList"] = [
          {
            connector: "-",
            label: t("money.detail_fee") || "",
            value: FormatterService.formatDecimal(fee || 0) || "",
          },
          {
            connector: "x",
            label: t("money.detail_rate") || "",
            value: FormatterService.formatDecimal(rate || 0, 4) || "",
          },
        ];
        const arriveDistance = estimated_delivery
          ? formatDistanceStrict(
              parseISO(estimated_delivery),
              new Date()
            ).split(" ")
          : [];
        const send =
          value.changed === "send"
            ? FormatterService.toNumber(value.sendMoney || "0")
            : source_amount;
        setData((data) => ({
          ...data,
          detailList,
          arriveDistance,
          [value.changed === "send" ? "getMoney" : "sendMoney"]:
            FormatterService.formatDecimal(
              value.changed === "send" ? target_amount : source_amount
            ),
          ...evalReached(data, send),
        }));
      }
    }
  }, []);

  const callbackDebouncer = (value: TData): void => {
    processAmount(value);
  };

  const { update } = useDebouncer<TData>({
    ms: 1000,
    before: beforeDebouncer,
    callback: callbackDebouncer,
  });

  const navigate = useNavigate();

  const onBack = () => navigate(ROUTE_MONEY);

  const onViewDetails = async () => {
    const { transfer } = await getWiseTransferDetail(firstTransferId || "");
    navigate(
      `${ROUTE_VIEW_DETAILS}/${firstTransferId}?quote_id=${transfer.quote_uuid}&recipient_id=${transfer.recipient_id}`
    );
  };

  const onCurrencyModal = () => navigate(ROUTE_CURRENCY_MODAL);

  const onNext = () => {
    executeCreatingQuoteAsync().then((wiseQuote: TCreateQuote | undefined) => {
      if (wiseQuote && "error" in wiseQuote) {
        setData((data) => ({ ...data, alertMessage: wiseQuote.error }));
      } else {
        navigateNextPage(wiseQuote);
      }
    });
  };

  const onCloseWelcome = () => {
    if (welcomeData) assignWelcomeViewed(welcomeData.variant);
    setWelcomeData(undefined);
  };

  const onApplyCurrency = (currency: TCurrency) => {
    const newData = {
      ...data,
      getCurrency: currency.code,
    };
    setData(newData);
    update(newData, true);
    navigate(ROUTE_MONEY);
  };

  const contextValue: TMoneyPageContext = {
    onBack,
    onApplyCurrency,
  };

  const onChangeSendMoney = (value: string) => {
    const newData: TData = {
      ...data,
      sendMoney: FormatterService.inputDecimal(value),
      changed: "send",
    };
    setData(newData);
    update(newData);
  };

  const onChangeGetMoney = (value: string) => {
    const newData: TData = {
      ...data,
      getMoney: FormatterService.inputDecimal(value),
      changed: "get",
    };
    setData(newData);
    update(newData);
  };

  const onBlurSendMoney = (value: string) => {
    setData((data) => ({
      ...data,
      sendMoney: FormatterService.formatDecimal(value),
    }));
  };

  const onBlurGetMoney = (value: string) => {
    setData((data) => ({
      ...data,
      getMoney: FormatterService.formatDecimal(value),
    }));
  };

  const onCloseLimitReachedAlert = () =>
    setData((data) => ({ ...data, limitReachedAlert: false }));

  const loadBalance = async () => {
    const results = await getBalance();
    if (results) {
      setData((data) => ({
        ...data,
        balance: results.balance,
        getCurrency: results.getCurrency,
        sendCurrency: results.currency,
      }));
    }
  };

  const loadLimits = async () => {
    const results = await getLimits();
    if (results) {
      const limitList = results.data.map(
        ({ limit_period, limit_amount, available }) => ({
          period: limit_period,
          label: t(`money.limit_${limit_period}`) || "",
          total: limit_amount,
          remaining: available,
        })
      );
      setData((data) => ({ ...data, limitList }));
    }
  };

  const clearAlertMessage = () =>
    setData((data) => ({ ...data, alertMessage: undefined }));

  useEffect(() => {
    clearQuoteIdOnStorage();
    loadBalance();
    loadLimits();
  }, []);

  const status: "ok" | "reached" | "exceeded" | "low" | undefined =
    useMemo(() => {
      const sendAmount = FormatterService.toNumber(data.sendMoney || "0");
      if (sendAmount > 0 && sendAmount < MIN_AMOUNT) return "low";
      if (sendAmount > (data.balance || 0)) return "exceeded";
      if (data.limitReached) return "reached";
      if (!!data.getCurrency && !!data.getMoney && !!data.sendMoney)
        return "ok";
      return undefined;
    }, [data]);

  return {
    ...data,
    contextValue,
    onCurrencyModal,
    onNext,
    onChangeSendMoney,
    onChangeGetMoney,
    onBlurSendMoney,
    onBlurGetMoney,
    onCloseLimitReachedAlert,
    status,
    welcomeData,
    firstTransferId,
    onCloseWelcome,
    onViewDetails,
    clearAlertMessage,
    minAmount: MIN_AMOUNT,
  };
};

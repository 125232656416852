export const manageRecipients = {
  en: {
    fieldErrors: {
      required: "this field is mandatory",
      minLength: "minimum length should be {{minLength}}",
      maxLength: "maximum length is {{maxLength}}",
      pattern: "invalid format",
      server: "{{defaultMessage}}",
    },
  },
};

import { useRequest } from "ahooks";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";

import { getQuote } from "services/APIService/Quotes/QuotesApi";
import { WiseQuote } from "services/APIService/Quotes/types";
import { StoreService, STORE_KEYS } from "services/StoreService";

type WiseQuoteContextValue = {
  quote: WiseQuote;
  loading?: boolean;
};
const WiseQuoteContext = createContext<WiseQuoteContextValue>(
  {} as WiseQuoteContextValue
);

export const WiseQuoteProvider: React.FC<
  PropsWithChildren<{ loaddingComponent?: React.ReactElement }>
> = ({ children, loaddingComponent }) => {
  const [searchParam] = useSearchParams();

  const cachedQuoteId = useRef<string | null>(null);

  const quoteId =
    searchParam.get("quote_id") ||
    StoreService.getString(STORE_KEYS.QUOTE_ID, null) ||
    cachedQuoteId.current;

  const { data, loading } = useRequest(
    async () => {
      if (!quoteId) return undefined;
      const quote = await getQuote(quoteId);
      cachedQuoteId.current = quoteId;
      return quote;
    },
    { refreshDeps: [quoteId] }
  );

  if (loading || !data) return loaddingComponent ?? null;

  const value = {
    quote: data,
    loading,
  };

  return (
    <WiseQuoteContext.Provider value={value}>
      {children}
    </WiseQuoteContext.Provider>
  );
};

export const useWiseQuote = (): WiseQuoteContextValue => {
  const values = useContext(WiseQuoteContext);
  return values;
};

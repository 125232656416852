import { TResource } from "../types";

export const money: TResource = {
  en: {
    title: "Send Money Internationally",
    info: "money info",
    balance: "{{value}} {{currency}}",
    detail: "{{value}} {{label}}",
    send_money: "You Send",
    get_money: "Recipient gets",
    currency: "Currency",
    currency_title: "Choose a Currency",
    currency_search: "Search",
    currency_popular: "Popular",
    currency_other: "Other",
    arrive_one: "Minute",
    arrive_other: "Minutes",
    arrive_prefix: "Should arrive in",
    continue: "Continue",
    detail_fee: "Fee",
    detail_rate: "Guaranteed rate",
    avaliable: "Avaliable to send: {{value}}",
    // eslint-disable-next-line
    low: "Minimum transfer amount is ${{value}} USD",
    limits: "Limits Apply",
    remaining: "Remaining",
    limit_86400: "24 Hour Limit",
    limit_2592000: "30 Day Limit",
    welcome_title_created: "Congratulations",
    welcome_title_sent: "Congratulations",
    welcome_title_verifying: "Account Created!",
    welcome_msg_created: "Your Wise account has successfully been created.",
    welcome_msg_sent: "Your first international money transfer has been sent!",
    welcome_msg_verifying: [
      "Your document verification is in progress. You can still transfer funds to family and friends.",
      "We will let you know when your documents have been verified.",
    ],
  },
};

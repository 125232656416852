import { Grid, Typography } from "@mui/material";
import LoaderIcon from "app/components/icons/LoaderIcon";
import { useEffect } from "react";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";

export default function LoadingPage(): JSX.Element {
  useEffect(() => {
    WebviewService.onOpenPage(WV_MESSAGE.PROCESSING);
  }, []);
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100vh", width: "100vw", p: 0, m: 0 }}
    >
      <Grid
        item
        alignItems="center"
        container
        direction="column"
        textAlign="center"
        justifyContent="center"
      >
        <LoaderIcon sx={{ animation: "spin 2s linear infinite" }} />

        <Typography variant="subtitle2" sx={{ fontWeight: 700, mt: 3 }}>
          Processing ...
        </Typography>
      </Grid>
    </Grid>
  );
}

import { useRequest } from "ahooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import {
  checkIfWiseUserAlredyExists,
  getWiseProfile,
  isWiseAccountAlreadyConnected,
} from "services/APIService/Accounts/api";
import { StoreService } from "services/StoreService";

type WiseUserContextValue = {
  profile_id?: number | string | null;
  user_id?: number;
  bishopToken?: string;
  alreadyHasWiseAccount?: boolean;
  shouldCreateProfile?: boolean;
  isLoggedIn: boolean;
  country_code?: string;
  app_type?: string;
  lang_code?: string;
  loading?: boolean;
  profileId?: string | number | null;
  firstTransferId?: string;
  kycState?: string;
};

const WiseUserContext = createContext({ isLoggedIn: false });

export const WiseUserProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const {
    env: { bibson, user_id },
    data: { channel, country_code, lang_code },
  } = StoreService.getBishop();

  const { data: createWiseUserData, loading: creatingWiseUserData } =
    useRequest(checkIfWiseUserAlredyExists, {
      refreshDeps: [bibson, user_id, channel, country_code, lang_code],
    });

  const isLoggedIn = isWiseAccountAlreadyConnected(createWiseUserData);

  const { data, loading: isLogingIn } = useRequest(
    async () => {
      if (!isLoggedIn) return undefined;
      const loginInfo = await getWiseProfile();
      return { ...loginInfo, profileId: loginInfo.profile_id };
    },
    { refreshDeps: [isLoggedIn, user_id] }
  );

  const {
    have_wise_account: alreadyHasWiseAccount,
    should_create_profile: shouldCreateProfile,
  } = createWiseUserData || {};

  const loading = creatingWiseUserData || isLogingIn;

  const value = {
    alreadyHasWiseAccount,
    shouldCreateProfile,
    isLoggedIn,
    loading,
    profileId: data?.profile_id,
    firstTransferId: data?.first_transfer,
    kycState: data?.kyc_state,
  };

  return (
    <WiseUserContext.Provider value={value}>
      {children}
    </WiseUserContext.Provider>
  );
};

export const useWiseUser = (): WiseUserContextValue => {
  const values = useContext(WiseUserContext);

  if (!values) {
    throw new Error("useWiseUser must be used within WiseUserProvider");
  }
  return values;
};

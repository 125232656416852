import React from "react";
import { Button, styled } from "@mui/material";
import { downloadRecipient } from "services/APIService/Transfers/TransfersApi";
import { useToggle } from "ahooks";
import { useTranslation } from "react-i18next";

const URL = window.URL || window.webkitURL;
const HiddenDownloadLink = styled("a")(() => ({ display: "none" }));
type DownloadReceiptButtonProps = {
  transferId: string | number;
};
const DownloadReceiptButton: React.FC<DownloadReceiptButtonProps> = ({
  transferId,
}) => {
  const { t } = useTranslation();
  const [downloading, { set: setLoading }] = useToggle(false);

  const downloadLinkRef = React.useRef<HTMLAnchorElement>(null);

  const downloadReceiptClickHanlder = async () => {
    setLoading(true);
    const downloadUrl = await downloadRecipient(transferId);
    setLoading(false);
    const node = downloadLinkRef.current;
    if (node) {
      node.href = downloadUrl;
      node.click();
      URL.revokeObjectURL(downloadUrl);
    }
  };

  const buttonText = downloading
    ? t("transaction.downloading")
    : t("transaction.download_reciept");

  return (
    <>
      <HiddenDownloadLink
        ref={downloadLinkRef}
        download={`receipt_${transferId}.pdf`}
      >
        download
      </HiddenDownloadLink>
      <Button
        disabled={downloading}
        size="large"
        variant="text"
        fullWidth
        sx={{ mt: 2 }}
        onClick={downloadReceiptClickHanlder}
      >
        {buttonText}
      </Button>
    </>
  );

  // const link = document.createElement('a');
  //   link.href = downloadUrl;
  //   link.setAttribute('download', 'file.pdf'); //or any other extension
  //   document.body.appendChild(link);
  //   link.click();
  //   // clean up "a" element & remove ObjectURL
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(downloadUrl);
  //   return downloadUrl
};

export default DownloadReceiptButton;

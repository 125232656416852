import { Box, Button, Typography } from "@mui/material";
import AlertIcon from "app/components/icons/AlertIcon";

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const FailedMessage: React.FC<{ reason: string | null }> = ({ reason }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ px: 6, textAlign: "center" }}>
      <AlertIcon fontSize="large" fill="none" sx={{ fontSize: "49px" }} />
      <Typography
        variant="h1"
        sx={{
          fontWeight: 510,
          p: 1,
        }}
      >
        {t([`failed.${reason}_message`, "failed.default_message"])}
      </Typography>
      <Typography variant="h3" sx={{ fontWeight: 400, mt: 2 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              [`failed.${reason}_description`, "failed.default_description"],
              { interpolation: { escapeValue: false } }
            ),
          }}
        ></div>
      </Typography>
    </Box>
  );
};
const FailedPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { reason = "" } = useParams();
  const okButtonClick = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FailedMessage reason={reason} />
      </Box>
      <Box sx={{ p: 4 }}>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={okButtonClick}
          sx={{ mt: 2 }}
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
};

export default FailedPage;

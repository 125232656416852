import axios from "axios";
import { PayfareResponse } from "../types";
import { buildGeneralAxiosConfig } from "../utils";
// import { StoreService } from 'services/StoreService';
import { WiseRecipient, WiseRecipientRequirements } from "./types";

// If for Post request, data will be attached bidy

type GettingListRecipientRequirmentParam = {
  quoteId?: string;
};
type GettingListRecipientRequirmentResponseData = {
  wise_recipient_requirements: WiseRecipientRequirements[];
};

export async function getListRecipientRequirements({
  quoteId,
}: GettingListRecipientRequirmentParam): Promise<WiseRecipientRequirements[]> {
  const endpoint = "/v6/international-transfer/recipient/requirements";
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestParams = {
    ...axiosDefaultConfig.params,
    quote_uuid: quoteId,
  };

  return await axios
    .get<PayfareResponse<GettingListRecipientRequirmentResponseData>>(
      endpoint,
      {
        params: requestParams,
        headers: axiosDefaultConfig.headers,
      }
    )
    .then((r) => r.data.data.wise_recipient_requirements);
}

export type CreateRecipientParams = {
  currency: string;
  recipient_type: string;
  profile_id: number;
  account_holder_name: string;
  details: object;
};

export type CreateRecipientReponseData = {
  wise_recipient: WiseRecipient;
};
export async function createRecipient(
  params: CreateRecipientParams
): Promise<WiseRecipient> {
  const endpoint = "/v6/international-transfer/recipient";
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestParams = {
    ...axiosDefaultConfig.params,
    ...params,
  };
  return await axios
    .post<PayfareResponse<CreateRecipientReponseData>>(
      endpoint,
      requestParams,
      {
        headers: axiosDefaultConfig.headers,
      }
    )
    .then((r) => r.data.data.wise_recipient);
}

export type RefreshRecipientRequirmentParams = {
  quote_uuid?: string;
  recipient_type: string;
  currency?: string;
  details: object;
};

type RefreshListRecipientRequirmentResponseData = {
  wise_recipient_requirements: WiseRecipientRequirements[];
};

export async function refreshRecipientRequirment(
  params: RefreshRecipientRequirmentParams
) {
  const endpoint = `/v6/international-transfer/recipient/requirements`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestParams = {
    ...axiosDefaultConfig.params,
    ...params,
  };
  return await axios
    .post<PayfareResponse<RefreshListRecipientRequirmentResponseData>>(
      endpoint,
      requestParams,
      {
        headers: axiosDefaultConfig.headers,
      }
    )
    .then((r) => r.data.data.wise_recipient_requirements);
}

type ListRecipientDataType = {
  wise_recipients?: { content: WiseRecipient[] };
};

type ListRecipientParam = {
  profileId?: string | null;
  currency?: string | null;
};

export async function getListRecipients(params?: ListRecipientParam) {
  const endPoint = "/v6/international-transfer/recipients";
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestParams = { ...axiosDefaultConfig.params, ...(params ?? {}) };
  const recipients = await axios
    .get<PayfareResponse<ListRecipientDataType>>(endPoint, {
      headers: axiosDefaultConfig.headers,
      params: requestParams,
    })
    .then((r) => r.data.data.wise_recipients?.content);
  return recipients || [];
}

type GetRecipientDataType = {
  wise_recipient: WiseRecipient;
};
export async function getRecipient(recipientId?: number | string) {
  const endPoint = "/v6/international-transfer/recipient";
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestParams = {
    ...axiosDefaultConfig.params,
    recipient_id: recipientId,
  };
  return await axios
    .get<PayfareResponse<GetRecipientDataType>>(endPoint, {
      headers: axiosDefaultConfig.headers,
      params: requestParams,
    })
    .then((r) => r.data.data.wise_recipient);
}

export async function deleteRecipient(recipientId?: number | string) {
  const endPoint = "/v6/international-transfer/recipient";
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestParams = {
    ...axiosDefaultConfig.params,
    recipient_id: recipientId,
  };
  return await axios.delete<PayfareResponse<string>>(endPoint, {
    params: requestParams,
    headers: axiosDefaultConfig.headers,
  });
}

import axios from "axios";
import { PayfareResponse } from "../types";
import { buildGeneralAxiosConfig } from "../utils";
import { WiseQuote } from "./types";

type QuoteResponseData = {
  wise_quote: WiseQuote;
};

type UpdateQuoteWithRecipientParam = {
  recipientId: number;
  quoteId: string | null;
};
type UpdateQuoteResponseData = QuoteResponseData;
export async function updateQuoteWithRecipient({
  recipientId,
  quoteId,
}: UpdateQuoteWithRecipientParam) {
  const endPoint = `/v6/international-transfer/quote/update-recipient`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const requestParams = {
    ...axiosDefaultConfig.params,
    recipient_id: recipientId,
    quote_id: quoteId,
  };

  const wiseQuote = await axios
    .post<PayfareResponse<UpdateQuoteResponseData>>(endPoint, requestParams, {
      headers: axiosDefaultConfig.headers,
    })
    .then((r) => r.data.data.wise_quote);
  return wiseQuote;
}

type GetQuoteResponseData = QuoteResponseData;
export async function getQuote(quoteId: string) {
  const endPoint = `/v6/international-transfer/quote/${quoteId}`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const wiseQuote = await axios
    .get<PayfareResponse<GetQuoteResponseData>>(endPoint, axiosDefaultConfig)
    .then((r) => r.data.data.wise_quote);
  return wiseQuote;
}

export type CreateQuoteAnominousParam = {
  source_currency: string;
  target_currency: string;
  source_amount?: number;
  target_amount?: number;
};

type CreateQuoteResponseData = QuoteResponseData;
export async function createAnonymousQuote(params: CreateQuoteAnominousParam) {
  const endPoint = `/v6/international-transfer/quote/anonymous`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const data = {
    ...params,
    ...axiosDefaultConfig.params,
  };
  const wiseQuote = await axios
    .post<PayfareResponse<CreateQuoteResponseData>>(endPoint, data, {
      headers: axiosDefaultConfig.headers,
    })
    .then((r) => r.data.data.wise_quote);
  return wiseQuote;
}

export type CreateQuoteProfileParam = CreateQuoteAnominousParam & {
  profile_id: number | string;
};

export async function createQuoteWithProfile(params: CreateQuoteProfileParam) {
  const endPoint = `/v6/international-transfer/quote/profile`;
  const axiosDefaultConfig = buildGeneralAxiosConfig();
  const data = {
    ...params,
    ...axiosDefaultConfig.params,
  };
  const wiseQuote = await axios
    .post<PayfareResponse<CreateQuoteResponseData>>(endPoint, data, {
      headers: axiosDefaultConfig.headers,
    })
    .then((r) => r.data.data.wise_quote);
  return wiseQuote;
}

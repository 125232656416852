import { CircularProgress } from "@mui/material";
import { VerticalCenteredBox } from "ad/atoms/VerticalCenteredBox";
import { FullPageTemplate } from "ad/templates/FullPageTemplate";

export const NoMatch = () => {
  return (
    <FullPageTemplate>
      <VerticalCenteredBox sx={{ alignItems: "center" }}>
        <CircularProgress size="1rem" />
      </VerticalCenteredBox>
    </FullPageTemplate>
  );
};

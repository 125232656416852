// Use this function to get the query params from the URL.
// Use this function outside of the React component because it will be called only once when the app is loaded.

export function getParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const bibson = urlParams.get("bibson");
  const oneTimeToken = urlParams.get("ott");
  const channel = urlParams.get("channel") || "paidapp_us";
  const ting = urlParams.get("ting");
  const userId = urlParams.get("gibby");
  const countryCode = urlParams.get("country_code") || "us";
  const langCode = urlParams.get("lang_code") || "en-us";
  return {
    bibson,
    oneTimeToken,
    channel,
    ting,
    userId,
    countryCode,
    langCode,
  };
}

import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { Box, Button, TextField, Typography } from "@mui/material";
import { CurrencySelect } from "ad/molecules/CurrencySelect";
import { SimpleMessageAlert } from "ad/molecules/SimpleMessageAlert";
import { SubtitleHeader } from "ad/molecules/SubtitleHeader";
import { FullPageTemplate } from "ad/templates/FullPageTemplate";
import { t } from "i18n";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { FormatterService } from "services/FormatterService";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";
import { DetailList } from "./DetailList";
import { LimitList } from "./LimitList";
import { LimitReachedAlert } from "./LimitReachedAlert";
import { MoneyPageContext } from "./MoneyPageContext";
import "./styles.css";
import { useMoneyPage } from "./useMoneyPage";
import { WelcomeAlert } from "./WelcomeAlert";

const MoneyPage = () => {
  const {
    detailList,
    limitList,
    limitReached,
    limitReachedAlert,
    arriveDistance,
    contextValue,
    onCurrencyModal,
    onNext,
    balance,
    sendMoney,
    sendCurrency,
    getMoney,
    getCurrency,
    onChangeSendMoney,
    onChangeGetMoney,
    onBlurSendMoney,
    onBlurGetMoney,
    onCloseLimitReachedAlert,
    status,
    welcomeData,
    alertMessage,
    clearAlertMessage,
    firstTransferId,
    onCloseWelcome,
    onViewDetails,
    minAmount,
  } = useMoneyPage();

  useEffect(() => WebviewService.onOpenPage(WV_MESSAGE.MONEY), []);

  return (
    <MoneyPageContext.Provider value={contextValue}>
      <FullPageTemplate
        header={
          <SubtitleHeader
            subtitle={t("money.balance", {
              value: FormatterService.formatDecimal(balance) || "-",
              currency: sendCurrency,
            })}
          />
        }
        footer={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Box sx={{ width: "98px" }}>
              <img src="/wise-powered-2.png" alt="" width="100%" />
            </Box>
            <Box sx={{ width: "100%", px: 4 }}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{
                  mt: 2,
                  textTransform: "none",
                }}
                onClick={onNext}
                disabled={status !== "ok"}
              >{`${t("money.continue")}`}</Button>
            </Box>
          </Box>
        }
      >
        <Box sx={{ display: "flex", flexDirection: "row", my: 2, mx: 2 }}>
          <TextField
            autoComplete="off"
            variant="standard"
            label={t("money.send_money")}
            sx={{ flex: 1 }}
            InputProps={{ sx: { fontWeight: "bold" } }}
            value={sendMoney || ""}
            onChange={({ target: { value } }) => onChangeSendMoney(value)}
            onBlur={({ target: { value } }) => onBlurSendMoney(value)}
            error={status === "exceeded" || status === "low"}
            helperText={
              status === "exceeded" ? (
                <>
                  <ErrorOutline fontSize="small" sx={{ mb: -0.5 }} />
                  {t("money.avaliable", {
                    value: FormatterService.formatDecimal(balance),
                  })}
                </>
              ) : status === "low" ? (
                <>
                  <ErrorOutline fontSize="small" sx={{ mb: -0.5 }} />
                  {t("money.low", {
                    value: minAmount,
                  })}
                </>
              ) : undefined
            }
          />
          <CurrencySelect label="" value={sendCurrency} sx={{ mx: 1 }} />
        </Box>
        <Box sx={{ minHeight: "6rem" }}>
          <DetailList list={detailList} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", my: 2, mx: 2 }}>
          <TextField
            autoComplete="off"
            variant="standard"
            label={t("money.get_money")}
            sx={{ flex: 1 }}
            InputProps={{ sx: { fontWeight: "bold" } }}
            value={getMoney || ""}
            onChange={({ target: { value } }) => onChangeGetMoney(value)}
            onBlur={({ target: { value } }) => onBlurGetMoney(value)}
          />
          <CurrencySelect
            label=""
            value={getCurrency}
            onClickSelect={onCurrencyModal}
            sx={{ mx: 1 }}
          />
        </Box>
        {arriveDistance !== undefined && (
          <Box sx={{ mx: 2, mb: 4, mt: -2 }}>
            <Typography variant="body2" className="pf-detail">
              {t("money.arrive_prefix")}
              <strong>&nbsp;{arriveDistance[0]}&nbsp;</strong>
              {arriveDistance[1]}
            </Typography>
          </Box>
        )}
        <Box sx={{ mx: 2, my: 2 }}>
          <LimitList list={limitList} />
        </Box>
      </FullPageTemplate>
      {limitReachedAlert ? (
        <LimitReachedAlert
          open
          period={limitReached}
          onClose={onCloseLimitReachedAlert}
        />
      ) : null}
      {welcomeData?.open ? (
        <WelcomeAlert
          variant={welcomeData.variant}
          open
          onClose={onCloseWelcome}
          onViewDetails={onViewDetails}
          firstTransferId={firstTransferId}
        />
      ) : null}
      {alertMessage ? (
        <SimpleMessageAlert
          open
          onClose={clearAlertMessage}
          message={alertMessage}
          webviewMessage={WV_MESSAGE.MONEY}
        />
      ) : null}
      <Outlet />
    </MoneyPageContext.Provider>
  );
};

export default MoneyPage;

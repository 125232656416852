import { UpgradeBishopTokenParams } from "./APIService/Accounts/api";

export enum STORE_KEYS {
  AUTH_TOKEN = "st_bibson",
  PAYFARE_ID = "st_payfare_id",
  USER_ID = "st_user_id",
  LANG_CODE = "st_lang_code",
  COUNTRY_CODE = "st_country_code",
  CHANNEL = "st_channel",
  QUOTE_ID = "st_quote_id",
  RECIPIENT_ID = "st_recipient_id",
}

export type TBishop = {
  env: {
    bibson: string;
    user_id: string;
    payfare_id: string;
  };
  data: {
    channel: string;
    country_code: string;
    lang_code: string;
    app_type: string;
    app_version: string;
  };
};

export const StoreService = {
  getString(key: STORE_KEYS, dflt?: string | null): string {
    return localStorage.getItem(key) || dflt || "";
  },
  getData(key: STORE_KEYS): string | null {
    return localStorage.getItem(key);
  },
  setData(
    key: STORE_KEYS,
    data: string | number | null | undefined,
    dflt?: string | null
  ) {
    const item =
      typeof data === "number" || typeof data === "string"
        ? String(data)
        : dflt;
    if (typeof item === "string") {
      localStorage.setItem(key, item);
    } else {
      localStorage.removeItem(key);
    }
  },
  setAuthData({
    channel,
    ting,
    token,
    userId,
    countryCode,
    langCode,
  }: UpgradeBishopTokenParams & { token?: string | null }) {
    this.setData(STORE_KEYS.CHANNEL, channel);
    this.setData(STORE_KEYS.PAYFARE_ID, ting);
    this.setData(STORE_KEYS.AUTH_TOKEN, token);
    this.setData(STORE_KEYS.USER_ID, userId);
    this.setData(STORE_KEYS.COUNTRY_CODE, countryCode);
    this.setData(STORE_KEYS.LANG_CODE, langCode);
  },
  getBishop(): TBishop {
    return {
      env: {
        bibson: this.getString(STORE_KEYS.AUTH_TOKEN),
        user_id: this.getString(STORE_KEYS.USER_ID),
        payfare_id: this.getString(STORE_KEYS.PAYFARE_ID),
      },
      data: {
        channel: this.getString(STORE_KEYS.CHANNEL),
        country_code: this.getString(STORE_KEYS.COUNTRY_CODE),
        lang_code: this.getString(STORE_KEYS.LANG_CODE),
        app_type: "WEB",
        app_version: "1.0.0",
      },
    };
  },
};

export function saveQuoteIdToStorage(quoteId: string): void {
  localStorage.setItem(STORE_KEYS.QUOTE_ID, quoteId);
}

export function clearQuoteIdOnStorage():void {
  localStorage.removeItem(STORE_KEYS.QUOTE_ID)
}

export function getQuoteIdFromStorage(): string | null {
  return localStorage.getItem(STORE_KEYS.QUOTE_ID);
}

import { compareDesc, parseISO } from "date-fns";
import { t } from "i18n";
import { transferTimeLine } from "services/APIService/Transfers/TransfersApi";
import { ITimelineInfo } from "./TransferTimeline";

export const getTransferTimeLines = async (
  transferId: number | string
): Promise<Array<ITimelineInfo>> => {
  const timelines = await transferTimeLine(transferId);
  const out: Array<ITimelineInfo> = [];
  timelines
    .sort((a, b) =>
      compareDesc(parseISO(a.occurred_at), parseISO(b.occurred_at))
    )
    .forEach((timeline) => {
      const { status, occurred_at } = timeline;
      const completedDate =
        occurred_at && occurred_at !== "" ? parseISO(occurred_at) : undefined;
      const job = t(`transaction.transfer_status_${status}`) || "";
      if (!out.length || out[0].job !== job)
        out.unshift({
          id: `${status}_${occurred_at}`,
          job,
          completedDate,
        });
    });
  return out;
};

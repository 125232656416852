import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

type TProps = {
  open: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
};

export const PageDialog = ({ open, header, footer, children }: TProps) => (
  <Dialog
    open={open}
    PaperProps={{
      sx: {
        justifySelf: "stretch",
        alignSelf: "stretch",
        margin: 0,
        marginTop: '40px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: "100%",
        height: "calc(100vh - 40px)",
        maxHeight: "inherit",
      },
    }}
  >
    {header && <DialogTitle>{header}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
    {footer && (
      <DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
        {footer}
      </DialogActions>
    )}
  </Dialog>
);

import { ThemeOptions } from "@mui/material";

type ComponentOverride = ThemeOptions["components"];
const components: ComponentOverride = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "200px",
      },
      sizeLarge: {
        padding: "16px",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none",
      },
      textSizeLarge: {
        color: "#0C2232",
      },
    },
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: "#000000",
        "&.Mui-focused": {
          fontWeight: 400,
          lineHeight: "13px",
          color: "#3B464B",
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        "&:after": {
          borderBottom: "1px solid #BEC3D2",
        },
      },
      input: {
        color: "#1E2021",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
      },
    },
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: "16px",
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        marginTop: 12,
      },
    },
  },
};

export default components;

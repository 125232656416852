import { Box } from "@mui/material";

type TProps = {
  sx?: Parameters<typeof Box>[0]["sx"];
  children: React.ReactNode;
};

export const VerticalCenteredBox = ({ sx, children }: TProps) => (
  <Box
    sx={{
      paddingX: 3,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: 2,
      height: "100%",
      ...sx,
    }}
  >
    {children}
  </Box>
);

import * as React from "react";
import { Grid, Box, Typography } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { useTranslation } from "react-i18next";

interface AmountMoneyProps {
  amount: number;
  currency: string;
}
const AmountMoney: React.FC<AmountMoneyProps> = ({
  amount,
  currency,
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h2">
        {t("transaction.amount", {
          amount,
          formatParams: { val: { minimumFractionDigits: 2 } },
        })}
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "4px", color: "#939CAF" }}>
        {currency}
      </Typography>
    </Box>
  );
};

type AmountValue = Pick<AmountMoneyProps, "currency" | "amount">;
interface ConversionRateProps {
  sendAmount: AmountValue;
  receiveAmount: AmountValue;
}

const ConversionRate: React.FC<ConversionRateProps> = ({
  sendAmount,
  receiveAmount,
}): JSX.Element => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid item xs={4}>
        <AmountMoney
          currency={sendAmount.currency}
          amount={sendAmount.amount}
        />
      </Grid>
      <Grid item>
        <ArrowForwardIosIcon fontSize="small" />
      </Grid>
      <Grid item xs={4}>
        <AmountMoney
          currency={receiveAmount.currency}
          amount={receiveAmount.amount}
        />
      </Grid>
    </Grid>
  );
};

export default ConversionRate;

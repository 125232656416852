import Close from "@mui/icons-material/Close";
import { Box, IconButton, styled } from "@mui/material";
import { IconTitleHeader } from "ad/molecules/IconTitleHeader";
import { PageDialog } from "ad/molecules/PageDialog";
import SearchInput from "app/components/RecipientSelect/SearchInput";
import { t } from "i18n";
import { useContext, useEffect } from "react";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";
import { CurrencyList } from "./CurrencyList";
import { MoneyPageContext } from "./MoneyPageContext";
import { useMoneyCurrencyModal } from "./useMoneyCurrencyModal";

type TProps = { open?: boolean };

const SearchInputWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1.5, 1, 1.5),
}));

export const MoneyCurrencyModal = ({ open }: TProps) => {
  const { onBack, onApplyCurrency } = useContext(MoneyPageContext);
  const { search, setSearch, popularCurrencies, otherCurrencies } =
    useMoneyCurrencyModal();
  useEffect(() => WebviewService.onOpenFrame(WV_MESSAGE.MONEY), []);
  return (
    <PageDialog
      open={!!open}
      header={
        <>
          <IconTitleHeader
            icon={
              <IconButton onClick={onBack}>
                <Close fontSize="small" />
              </IconButton>
            }
            title={`${t("money.currency_title")}`}
          />
          <SearchInputWrapper>
            <SearchInput
              inputProps={{
                onChange: ({ target: { value } }) => setSearch(value),
                value: search,
                placeholder: `${t("money.currency_search")}`,
              }}
            />
          </SearchInputWrapper>
        </>
      }
    >
      <CurrencyList
        list={popularCurrencies}
        title={`${t("money.currency_popular")}`}
        onSelect={onApplyCurrency}
      />
      <CurrencyList
        list={otherCurrencies}
        title={`${t("money.currency_other")}`}
        onSelect={onApplyCurrency}
      />
    </PageDialog>
  );
};

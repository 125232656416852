import { Box, Typography } from "@mui/material";

type TArgs = {
  subtitle?: string;
};

export const SubtitleHeader = ({ subtitle }: TArgs) => (
  <Box sx={{ textAlign: "center" }}>
    <Typography variant="body2" className="pf-title-subtitle">
      {subtitle}
    </Typography>
  </Box>
);

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TPersonalPageContext } from "./PersonalPageContext";
import { TPersonalData } from "./types";

const ROUTE_CONNECT = "/personal/pre-login";

export const usePersonalPage = () => {
  const [data] = useState<TPersonalData>();
  const navigate = useNavigate();

  const onConnect = () => navigate(ROUTE_CONNECT);


  const contextValue: TPersonalPageContext = {
    data
  };

  return {
    data,
    onConnect,
    contextValue,
  };
};

import { Box, Icon, Typography } from "@mui/material";

type TArgs = {
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  title?: string;
  subtitle?: string;
};

export const IconTitleHeader = ({
  icon,
  iconRight,
  title,
  subtitle,
}: TArgs) => (
  <>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>{icon}</Box>
      <Box sx={{ flexGrow: 1, textAlign: "center", fontWeight: "bold" }}>
        {title && (
          <div>
            <Typography variant="h6">{title}</Typography>
          </div>
        )}
      </Box>
      <Box>
        <Icon>{iconRight}</Icon>
      </Box>
    </Box>
    {subtitle && (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="body2" className="pf-title-subtitle">
          {subtitle}
        </Typography>
      </Box>
    )}
  </>
);

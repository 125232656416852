import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Typography } from "@mui/material";

import { t } from "i18n";

import React, { useEffect } from "react";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";

const CreatedMessage: React.FC<{}> = () => {
  return (
    <Box sx={{ paddingY: 10, paddingX: 2, textAlign: "center" }}>
      <CheckCircleOutlineIcon
        fontSize="large"
        sx={{ fontSize: "49px", color: "#20C185" }}
      />
      <Typography
        variant="h2"
        sx={{
          fontSize: "2rem",
          fontWeight: 510,
          lineHeight: "38px",
          textAlign: "center",
        }}
      >
        {t("personal.post_title")}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontWeight: 400,
          lineHeight: "30px",
          textAlign: "center",
          p: 0.5,
        }}
      >
        {t("personal.post_message")}
      </Typography>

      <Typography
        variant="h3"
        sx={{
          fontWeight: 400,
          lineHeight: "30px",
          textAlign: "center",
          p: 0.5,
        }}
      >
        {t("personal.post_notify")}
      </Typography>
    </Box>
  );
};

export const PostLoginCreated: React.FC<{ onNext: () => void }> = ({
  onNext,
}) => {
  useEffect(() => WebviewService.onOpenPage(WV_MESSAGE.PERSONAL));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CreatedMessage />
      </Box>
      <Box sx={{ p: 4 }}>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={onNext}
        >
          {t("personal.post_continue")}
        </Button>
      </Box>
    </Box>
  );
};

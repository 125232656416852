import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { FlagIcon } from "ad/atoms/FlagIcon";

type TProps = {
  label?: string;
  value?: string;
  sx?: Parameters<typeof FormControl>[0]["sx"];
  onClickSelect?: () => void;
};

export const CurrencySelect = ({ sx, label, value, onClickSelect }: TProps) => {
  return (
    <FormControl variant="standard" sx={{ maxWidth: 104, ...sx }}>
      <InputLabel>{label}</InputLabel>

      <Input
        id="filled-adornment-password"
        value={value || ""}
        onClick={onClickSelect}
        startAdornment={
          <FlagIcon
            country={value?.substring(0, 2).toLowerCase()}
            fontSize="medium"
            sx={{ mr: 1 }}
          />
        }
        endAdornment={
          onClickSelect ? (
            <InputAdornment position="end">
              <IconButton edge="end">
                <ArrowDropDownIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
        readOnly
      />
    </FormControl>
  );
};

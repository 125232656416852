import { useRequest } from "ahooks";
import { useCallback } from "react";
import {
  createUserProfile,
  createWiseUser,
  getReclaimLink,
  getWiseLoginLink,
} from "services/APIService/Accounts/api";


export const usePersonalPreCreateModal = () => {

  const createUser = useCallback(async () => {
    let redirectLink;
    const createData = await createWiseUser();
    const { email_exist } = createData || {};

    if (email_exist) {
      await createUserProfile();
      redirectLink = await getWiseLoginLink();
    } else {
      await createUserProfile();
      redirectLink = await getReclaimLink();
    }
    return redirectLink
  }, []);

  const { data, loading, error } = useRequest(createUser, {
    refreshDeps: [createUser],
    debounceWait: 500,
  });

  return { data, loading, error };
};

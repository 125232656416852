type TConfig = {
  [key in "wiseOauthUrl" | "wiseClientId" | "postLoginUri" | "bishop"]: string;
};

const areaList = window.location.hostname.split(".")[0].split("-");

const isLocal = (areaList: string[]): boolean =>
  areaList.length === 1 && ["127", "localhost"].includes(areaList[0]);

const getProfileAndArea = (areaList: string[]): [string, string?] => {
  if (isLocal(areaList)) return ["local"];
  if (areaList.length === 1) return ["live"];
  return ["area", areaList[0]];
};

const [profile, area] = getProfileAndArea(areaList);

let config: TConfig =
  profile === "live"
    ? {
        wiseOauthUrl: "https://api.transferwise.com/oauth/authorize",
        wiseClientId: "payfare",
        postLoginUri: `${window.location.protocol}//${window.location.host}/personal/post-login`,
        bishop: "",
      }
    : {
        wiseOauthUrl: "https://sandbox.transferwise.tech/oauth/authorize",
        wiseClientId: "payfare",
        postLoginUri: `${window.location.protocol}//${window.location.host}/personal/post-login`,
        bishop: "",
      };

export const ConfigService = {
  ...config,
  profile,
  area,
  evalFlagUri: (country: string) => `https://flagcdn.com/w80/${country}.png`,
};

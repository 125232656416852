export enum ENextStep {
  CREATE_PROFILE = "create_profile",
  REDIRECT_LINK = "get_redirect_link",
  RECLAIM_LINK = "get_reclaim_link",
}

export type TBishopEnv = {
  bibson: string;
  user_id: string;
  payfare_id: string;
};

export type TErr = {
  error: string;
  method: string;
  catch?: boolean;
};

export type TErrData =
  | {
      errors?: {
        code?: string;
        field?: string;
        message?: string;
      }[];
    }
  | undefined;

export type TGetCards = {
  IN: {
    channel: string;
    lang_code: string;
    country_code: string;
    app_type: string;
    app_version: string;
  };
  OUT: {
    data: {
      cards: {
        ting: string;
        card_status: string;
        currency_code: string;
      }[];
      auto_method?: {
        ting: string;
      };
    };
  };
};

export type TGetBalance = {
  IN: {
    channel: string;
    card_ting: string;
    lang_code: string;
    country_code: string;
    app_type: string;
  };
  OUT: {
    data: {
      balance: number;
    };
  };
};

export type TGetLimits = {
  IN: {
    user_id: string;
    channel: string;
    lang_code: string;
    country_code: string;
    app_type: string;
    provider_id: number;
  };
  OUT: {
    data: [
      {
        limit_period: number;
        limit_amount: number;
        available: number;
      }
    ];
  };
};

export type TEvalAmount = {
  IN: {
    channel: string;
    country_code: string;
    app_type: string;
    lang_code: string;
    user_id: string;
    source_currency: string;
    target_currency: string;
    source_amount: number | null;
    target_amount: number | null;
  };
  OUT: {
    data: {
      wise_quote: {
        status: string;
        rate: number;
        estimated_delivery: string;
        source_amount: number;
        target_amount: number;
        fee: number;
        fee_currency: string;
      };
    };
  };
};

export type TGetCurrencies = {
  IN: {
    channel: string;
    lang_code: string;
    country_code: string;
    app_type: string;
    user_id: string;
  };
  OUT: {
    data: {
      unique_currencies: {
        currency: string;
        currency_name: string;
        popular?: boolean;
      }[];
    };
  };
};

export type TSendWiseCode = {
  IN: {
    code: string;
    channel: string;
    lang_code: string;
    country_code: string;
    app_type: string;
    user_id: string;
    redirect_uri: string;
  };
  OUT: {
    data: {
      is_created: string | boolean;
    };
  };
};

export type TCreateProfile = {
  IN: {
    channel: string;
    lang_code: string;
    country_code: string;
    app_type: string;
    user_id: string;
  };
  MIDDLE: {
    data: {
      next_step: string;
    };
  };
  OUT: {
    next_step: ENextStep;
  };
};

export type TCreateNextStep = {
  IN: {
    channel: string;
    lang_code: string;
    country_code: string;
    app_type: string;
    user_id: string;
    redirect_uri: string;
  };
  MIDDLE: {
    data: {
      redirect_link?: string;
      reclaim_link?: string;
    };
  };
  OUT: {
    uri?: string;
  };
};

type ErrorData = {
  code: string;
  message: string;
  path: string;
  arguments: Array<object>;
};
export interface PayfareError {
  code: string;
  message: string | null;
  field: string;
  data: {
    errors: Array<ErrorData>;
  };
}
export interface PayfareResponse<T> {
  status: string;
  data: T;
  errors?: PayfareError[];
  message?: string | object;
}

export type NullableProperty<T> = { [P in keyof T]: T[P] | null };

import { Box, Button, Typography } from "@mui/material";
import { PageDialog } from "ad/molecules/PageDialog";
import { t } from "i18n";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";
import { usePersonalPage } from "./usePersonalPage";

type TProps = { open?: boolean };

export const PersonalCheckingModal = ({ open }: TProps) => {
  const { onConnect } = usePersonalPage();
  const [searchParam] = useSearchParams();
  const email = searchParam.get("email");

  useEffect(() => WebviewService.onOpenModal(WV_MESSAGE.CHECKING), []);

  return (
    <PageDialog
      open={!!open}
      footer={
        <>
          <Button
            onClick={onConnect}
            variant="contained"
            color="secondary"
            sx={{
              borderRadius: 40,
              textTransform: "none",
              marginY: 1,
              paddingY: 2,
              width: "90%",
              fontSize: "1rem",
              fontWeight: "700",
            }}
          >{`${t("personal.connect")}`}</Button>
        </>
      }
    >
      <Box sx={{ textAlign: "center", marginX: "10%" }}>
        <Box sx={{ my: 5 }}>
          <img
            src="/paidapp_wise_1-2.png"
            alt="logo"
            style={{ maxWidth: "100%", objectFit: "contain" }}
          />
        </Box>
        <Typography
          variant="body2"
          className="pf-title"
          sx={{ paddingY: 1 }}
        >{`${t("personal.checking_body_1")}`}</Typography>
        <Typography variant="body2" className="pf-item" sx={{ paddingY: 1 }}>
          {email}
        </Typography>
        <Typography
          variant="body2"
          className="pf-title"
          sx={{ paddingY: 1 }}
        >{`${t("personal.checking_body_2")}`}</Typography>
      </Box>
    </PageDialog>
  );
};

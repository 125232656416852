import { Box, Typography } from "@mui/material";
import { FullPageTemplate } from "ad/templates/FullPageTemplate";
import { t, tmap } from "i18n";
import { useEffect } from "react";
import { WebviewService, WV_MESSAGE } from "services/WebviewService";

export const PersonalInformationPage = () => {
  useEffect(() => WebviewService.onOpenPage(WV_MESSAGE.PERSONAL_INFO), []);
  return (
    <FullPageTemplate>
      <Box
        sx={{
          mt: 12,
          paddingX: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Typography variant="h4">{`${t("personal.content_title")}`}</Typography>
        {tmap("personal.content_body").map((item, i) => (
          <Typography
            key={i}
            variant="body2"
            sx={{ color: "#1E2021", fontSize: '1rem', lineHeight: '1.25rem' }}
          >{`${item}`}</Typography>
        ))}
      </Box>
    </FullPageTemplate>
  );
};

import { Typography } from "@mui/material";
import { VerticalCenteredBox } from "ad/atoms/VerticalCenteredBox";
import { PageDialog } from "ad/molecules/PageDialog";

type TProps = { open?: boolean };

export const AccountPostLinkModal = ({ open }: TProps) => {
    return (
    <PageDialog
      open={!!open}
    >
      <VerticalCenteredBox sx={{ alignItems: "center" }}>
        <Typography variant="body2">post-link</Typography>
      </VerticalCenteredBox>
    </PageDialog>
  );
};

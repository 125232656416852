import Timeline from "@mui/lab/Timeline";
import TimelineConnector, {
  TimelineConnectorProps,
} from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot, { TimelineDotProps } from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Grid, Typography } from "@mui/material";
import { useRequest } from "ahooks";
import LoaderIcon from "app/components/icons/LoaderIcon";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { getTransferTimeLines } from "./getTransferTimeLines";

type TimelineItemState = "done" | "current" | "going";

type TimelineDotOverrideStyle = Pick<TimelineDotProps, "sx" | "variant">;

const TimelineDotStyledByStates: Record<
  TimelineItemState,
  TimelineDotOverrideStyle
> = {
  done: {
    sx: { backgroundColor: "#377D31" },
    variant: "filled",
  },
  current: {
    sx: { borderColor: "#377D31" },
    variant: "outlined",
  },
  going: {
    sx: { backgroundColor: "#D2E3D0" },
    variant: "filled",
  },
};

type TimelineConnectorOverrideStyle = TimelineConnectorProps["sx"];
const doneConnectorStyle: TimelineConnectorOverrideStyle = {
  backgroundColor: "#377D31",
};

const goingConnectorStyle: TimelineConnectorOverrideStyle = {
  backgroundColor: "#D2E3D0",
};

const TimelineConnectorStyledByState: Record<
  TimelineItemState,
  TimelineConnectorOverrideStyle
> = {
  done: doneConnectorStyle,
  current: goingConnectorStyle,
  going: goingConnectorStyle,
};

export interface ITimelineInfo {
  job: string;
  completedDate?: Date;
  id: string | number;
}

interface TimelineTransferItemProps {
  state: TimelineItemState;
  isLastItem?: boolean;
  timelineInfo: ITimelineInfo;
}

const TransferTimeLineItem: React.FC<TimelineTransferItemProps> = ({
  state,
  isLastItem,
  timelineInfo,
}) => {
  const { t } = useTranslation();
  const dotOverridedProps = TimelineDotStyledByStates[state];
  const connectorOverridedProps = TimelineConnectorStyledByState[state];
  const { job, completedDate } = timelineInfo;
  const minHeight = isLastItem ? "auto" : undefined;
  return (
    <TimelineItem sx={{ "&:before": { px: 0, flex: "none" }, minHeight }}>
      <TimelineSeparator>
        <TimelineDot color="success" {...dotOverridedProps} />
        {!isLastItem && <TimelineConnector sx={connectorOverridedProps} />}
      </TimelineSeparator>
      <TimelineContent sx={{ display: "flex" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="start"
          marginTop={0.5}
        >
          <Grid item xs={8}>
            <Typography variant="subtitle1">{job}</Typography>
          </Grid>
          <Grid item sx={{ flex: "none" }}>
            <Typography variant="body1">
              {completedDate &&
                t("transaction.transfer_step_completed_at", {
                  completedDate,
                  formatParams: {
                    completedDate: { month: "short", day: "numeric" },
                  },
                })}
            </Typography>
          </Grid>
        </Grid>
      </TimelineContent>
    </TimelineItem>
  );
};

interface TransferTimelineProps {
  transfer_id: number | string;
}

const TransferTimeline: React.FC<TransferTimelineProps> = ({ transfer_id }) => {
  const { data: timelines, loading } = useRequest(
    () => getTransferTimeLines(transfer_id),
    {
      refreshDeps: [transfer_id],
    }
  );

  if (loading)
    return <LoaderIcon sx={{ animation: "spin 2s linear infinite" }} />;

  if (!timelines) return <></>;

  let lastIndexWithCompletedDate: number;
  return (
    <Timeline>
      {timelines.map((timelineInfo, index) => {
        const isLastItem = index === timelines.length - 1;
        let state: TimelineItemState = "done";

        if (lastIndexWithCompletedDate && index > lastIndexWithCompletedDate)
          state = "going";
        if (!lastIndexWithCompletedDate && !timelineInfo.completedDate) {
          lastIndexWithCompletedDate = index;
          state = "current";
        }
        return (
          <TransferTimeLineItem
            key={timelineInfo.job}
            state={state}
            timelineInfo={timelineInfo}
            isLastItem={isLastItem}
          />
        );
      })}
    </Timeline>
  );
};

export default TransferTimeline;

const typography = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif",
  h1: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "38px",
    color: "#000000",
  },
  h2: {
    /* amount on review page */
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "29px",
    color: "#000000",
  },
  h3: {
    /* name on transaction detail */
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#000000",
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: 400,
  },
  h6: {
    fontSize: "0.9rem",
    fontWeight: "700",
  },
  subtitle1: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
  },
  body1: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
  },
  body2: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#9DA2A5",
  },
};

export default typography;

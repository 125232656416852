import { t } from "i18n";

export type TWebviewMessage = {
  title?: string;
  back?: "back" | "close";
  right_text?: string;
  info?: string;
  info_id?: string;
  info_code?: number;
  back_route?: string;
  right_route?: string;
  hover?: boolean;
};

export const WV_MESSAGE: { [key: string]: TWebviewMessage } = {
  MONEY: {
    back: "back",
    back_route: "",
    title: t("money.title"),
    info: "wise-webapp-info",
    info_code: 4008,
    info_id: "1mWkpIgo41POYWuJk6tDwQ",
  },
  ACCOUNT: {
    back: "back",
    back_route: "/money",
  },
  CHECKING: {
    back: "back",
    back_route: "/account/link",
  },
  PERSONAL: {
    back: "back",
    back_route: "/money",
  },
  PERSONAL_MSG: {
    back: "close",
    back_route: "",
  },
  PERSONAL_INFO: {
    back: "close",
    back_route: "/account/link",
  },
  RECIPIENTS: {
    back: "back",
    back_route: "/money",
    right_text: "Add",
    right_route: "/recipients/add",
  },
  RECIPIENTS_ADD: {
    back: "back",
    back_route: "/recipients/list",
  },
  RECIPIENT_EDIT: {
    title: "View Recipient",
    back: "back",
    back_route: "/recipients/list",
  },
  REVIEW: {
    title: "Review and Send",
    back: "back",
    back_route: "/recipients/list",
  },
  PROCESSING: {
    title: "",
  },
  PAYMENT_DETAILS: {
    back: "close",
    back_route: "/money",
    title: "Payment Details",
  },
  PAYMENT_CONFIRMATION: {
    back: "close",
    back_route: "",
    title: "",
  },
  ACCOUNT_LINK_LINK_CLICK: {
    back: "back",
    back_route: "/account/link",
    title: "",
  },
  REVIEW_LINK_CLICK: {
    back: "back",
    back_route: "/transaction/review",
    title: "",
  },
};

export const WebviewService = {
  _sendMessage(message: TWebviewMessage) {
    const { title, hover, ...rest } = message;
    const { log } = window.console; // to avoid direct call eslint error
    log(
      JSON.stringify({
        ...(hover ? undefined : rest),
        title: title || "",
      })
    );
  },
  sendToastMessage(toast: string) {
    const { log } = window.console; // to avoid direct call eslint error
    log(JSON.stringify({ toast }));
  },
  onOpenPage(args: TWebviewMessage | undefined) {
    this._sendMessage(args || {});
  },
  onOpenModal(args: TWebviewMessage) {
    this._sendMessage({ ...args, hover: true });
    return () => this._sendMessage({ ...args, hover: false });
  },
  onOpenFrame(args: TWebviewMessage) {
    this._sendMessage({ title: "" });
    return () => this._sendMessage({ ...args, hover: false });
  },
  onToggleModal(args: TWebviewMessage, open: boolean | undefined) {
    this._sendMessage({ ...args, hover: open });
    return () => open && this._sendMessage({ ...args, hover: false });
  },
};

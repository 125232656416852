import { Outlet } from "react-router-dom";
import { AccountPageContext } from "./AccountPageContext";
import { useAccountPage } from "./useAccountPage";

const AccountPage = () => {
  const { contextValue } = useAccountPage();
  return (
    <AccountPageContext.Provider value={contextValue}>
      <Outlet />
    </AccountPageContext.Provider>
  );
};

export default AccountPage;

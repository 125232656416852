import LoadingPage from 'ad/molecules/LoadingPage';
import { useRequest, useLocalStorageState } from 'ahooks';
import { PropsWithChildren } from 'react';

import {
  upgradeBishopToken,
  UpgradeBishopTokenParams,
} from 'services/APIService/Accounts/api';
import { StoreService } from 'services/StoreService';

const TOKEN_FALLBACK = true;
const LAST_USED_OTT_TOKEN_STORAGE_KEY = 'st_ott';

type OneTimeTokenWrapperProps = {} & UpgradeBishopTokenParams;

export const OneTimeTokenWrapper: React.FC<
  PropsWithChildren<OneTimeTokenWrapperProps>
> = ({ children, bibson, ...restProps }) => {
  const { ting, userId, oneTimeToken, langCode, channel, countryCode } =
    restProps;

  const [lastUsedToken, setLastUsedToken] = useLocalStorageState<string>(
    LAST_USED_OTT_TOKEN_STORAGE_KEY,
    { defaultValue: '' }
  );
  // Add a debounce to avoid multiple requests
  const { loading, error } = useRequest(
    async () => {
      let token;
      if (oneTimeToken && oneTimeToken !== lastUsedToken) {
        token = await upgradeBishopToken({
          ting,
          userId,
          oneTimeToken,
          langCode,
          channel,
          countryCode,
        });
        setLastUsedToken(oneTimeToken);
        StoreService.setAuthData({ ...restProps, token });
      }
      return token;
    },
    { refreshDeps: [oneTimeToken, userId], debounceWait: 500 }
  );
  if (error) return <div>Something went wrong</div>;
  if (loading) return <LoadingPage />;
  return <>{children}</>;
};
